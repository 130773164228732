import Flatpickr from 'flatpickr'
import 'flatpickr/dist/themes/dark.css'
import { Instance } from 'flatpickr/dist/types/instance'
import { useEffect, useRef, useState } from 'react'
import { classNames } from '../../commons/Util/Util'
import TimezoneSelector from './TimezoneSelector'
import WithTitle from '../WithTitle/WithTitle'
import WithValidation from '../WithValidation/WithValidation'
import styles from './DatePicker.scss'
import { ITimezoneOption } from 'react-timezone-select'
import spacetime from 'spacetime'

const flatpickrToSpaceTimeConversionMap = {
  Y: '{year}',
  n: '{iso-month}',
  j: '{date}',
  H: '{hour-24-pad}',
  i: '{minute-pad}',
  S: '{second-pad}'
}

const flatpickrDateFormatToSpaceTimeFormat = (
  dateFormat: string,
  noCalendar: boolean
): string => {
  const separator = noCalendar ? ':' : '/'
  const dateParts = dateFormat.split(separator)
  const spaceTimeFormatParts = dateParts.map(
    (datePart: string) =>
      flatpickrToSpaceTimeConversionMap[datePart] || datePart
  )
  return spaceTimeFormatParts.join(separator)
}

type Props = {
  className?: string
  options: DatePickerOptions
  onUpdate?: (dates: Date, currentDateString: string) => void
  defaultDate?: string
  emptyDateText?: string
}

export type DatePickerOptions = {
  dateFormat: string
  noCalendar?: boolean
  enableTime?: boolean
  enableTimezoneSelector?: boolean
  timezone?: string
}

const DatePicker = (props: Props): JSX.Element => {
  const flatpickrRef = useRef<HTMLInputElement | null>(null)
  const flatpickrInstance = useRef<Instance | null>(null)

  const { options, defaultDate, className, emptyDateText } = props

  const getCurrentDate = (defaultDate?: string): Date => {
    let currentDate: Date

    if (defaultDate) {
      currentDate = new Date(defaultDate)
    }
    else {
      currentDate = new Date()
      currentDate.setHours(0, 0, 0, 0)
    }

    return currentDate
  }

  const [currentDate, setCurrentDate] = useState<Date>(
    getCurrentDate(defaultDate)
  )

  useEffect(() => {
    setCurrentDate(getCurrentDate(props.defaultDate))
  }, [defaultDate])

  useEffect(
    () => () => {
      removeFlatpickr()
    },
    []
  )

  const getFormattedDate = (date?: Date): string => {
    if (!defaultDate && emptyDateText) {
      return emptyDateText
    }
    if (options.timezone) {
      const spaceDate = spacetime(date || currentDate)
      const spaceTimeFormat = flatpickrDateFormatToSpaceTimeFormat(
        options.dateFormat,
        options.noCalendar ?? false
      )
      return spaceDate.goto(options.timezone).format(spaceTimeFormat)
    }

    return Flatpickr.formatDate(date || currentDate, options.dateFormat)
  }

  const createDatePicker = (): void => {
    const defaultOptions = {
      enableTime: true,
      time_24hr: true,
      allowInput: false,
      onClose: onUpdate(),
      defaultDate: currentDate
    }

    if (options.timezone) {
      // If timezone is provided, we need to set defaultDate to undefined
      // so that flatpickr doesn't convert the date to local time
      defaultOptions.defaultDate = undefined as unknown as Date
    }

    removeFlatpickr()
    if (flatpickrRef.current) {
      flatpickrInstance.current = Flatpickr(flatpickrRef.current, {
        ...defaultOptions,
        ...options
      })
      flatpickrInstance.current.open()
    }
  }

  const removeFlatpickr = (): void => {
    flatpickrInstance.current && flatpickrInstance.current.destroy()
  }

  const onUpdate
    = () =>
      (dates: Date[], currentDateString: string): void => {
        let newDate
        let dateString
        if (options.timezone) {
          const spaceDate = spacetime(dates[0])
          const newTimezoneOffset = spaceDate.goto(options.timezone).timezone()
            .current.offset
          newDate = spaceDate
            .add(
              spaceDate.timezone().current.offset - (newTimezoneOffset || 0),
              'hour'
            )
            .toNativeDate()
          dateString = getFormattedDate(newDate)
        }
        else {
          newDate = dates[0]
          dateString = currentDateString
        }

        props.onUpdate && props.onUpdate(newDate, dateString)
        setCurrentDate(newDate)
        removeFlatpickr()
      }

  const onTimezoneUpdate = (timezone: ITimezoneOption) => {
    const spaceDate = spacetime(currentDate)
    const newDate = spaceDate.add(
      spaceDate.timezone().current.offset - (timezone.offset || 0),
      'hour'
    )
    const nativeDate = newDate.toNativeDate()
    setCurrentDate(nativeDate)
    props.onUpdate && props.onUpdate(nativeDate, getFormattedDate(nativeDate))
  }

  const classes = classNames(
    styles.datePicker,
    className,
    options.enableTimezoneSelector ? styles.withTimezone : ''
  )
  return (
    <div className={ styles.container }>
      <input
        className={ classes }
        onClick={ (): void => createDatePicker() }
        onChange={ (): void => {} }
        ref={ flatpickrRef }
        value={ getFormattedDate() }
      />
      { options.enableTimezoneSelector && (
        <span>
          <TimezoneSelector
            className={ styles.timezoneSelect }
            value={
              options.timezone
              || Intl.DateTimeFormat().resolvedOptions().timeZone
            }
            onUpdate={ (timezone: ITimezoneOption): void =>
              onTimezoneUpdate(timezone)
            }
            useShortLabels
          />
        </span>
      ) }
    </div>
  )
}

const TitledDatePicker = WithTitle(DatePicker)
export default TitledDatePicker
export const ValidatedDatePicker = WithValidation(TitledDatePicker)
