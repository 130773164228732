import {
  EsportsMappingRequest,
  ParticipantDetails,
  TeamDetails,
  PlatformGameDetails,
  PlatformGameState,
  LolParticipant,
  ValParticipant,
  WrParticipant
} from '@riotgames/api-types/admin-api/Game_management.type'
import { Sport } from '@riotgames/api-types/elds/Common.type'
import { LolPlatformTeamId } from './AdminUtils.config'
import guidToAgentMap from './val-agents.json'

// TODO: Importing this from TournamentUtils causes it to be undefined at unit
// TODO: test time for some reason.  Remove this when we move away from the
// TODO: multi-export index.ts files, or figure out what is so fragile about them
// TODO: in the Jest testing environment
const createFactory
  = <T>(defaults: T) =>
    (...partials: Partial<T>[]): T =>
      Object.assign({}, defaults, ...partials)

export const createLolParticipant = createFactory<LolParticipant>({
  platformAccountId: '',
  championId: ''
})

export const createValParticipant = createFactory<ValParticipant>({
  puuid: '',
  guid: ''
})

export const createWrParticipant = createFactory<WrParticipant>({
  puuid: ''
})

export const createParticipantDetails = createFactory<ParticipantDetails>({
  participantId: -1,
  displayName: '',
  esportsPlayerId: '',
  lol: createLolParticipant(),
  val: createValParticipant(),
  wr: createWrParticipant()
})

export const createTeamDetails = createFactory<TeamDetails>({
  platformTeamId: -1,
  esportsTeamId: '',
  participants: [
    createParticipantDetails(),
    createParticipantDetails(),
    createParticipantDetails(),
    createParticipantDetails(),
    createParticipantDetails()
  ]
})

export const createEsportsMappingRequest = createFactory<EsportsMappingRequest>(
  {
    esportsGameId: '',
    teams: [
      createTeamDetails({
        platformTeamId: LolPlatformTeamId.Blue
      }),
      createTeamDetails({
        platformTeamId: LolPlatformTeamId.Red
      })
    ]
  }
)

export const createPlatformGameDetails = createFactory<PlatformGameDetails>({
  platformGameId: '',
  name: '',
  esportsGameId: '',
  state: PlatformGameState.InProgress,
  teams: [createTeamDetails(), createTeamDetails()]
})

// Helper will default to blue team Index, returns index of side passed in
// This will work for lol and valorant. Will probably update this method as we add more games, or teams etc etc
// TODO: Improvise a better alternative than this on how to find what team is on what side
export const getTeamIndex = (
  sport: string,
  side: string,
  teams: TeamDetails[]
) => {
  if (sport === Sport.Lol) {
    side = side.charAt(0).toLocaleUpperCase() + side.substring(1)
    return teams.findIndex(
      (team) =>
        team.platformTeamId
        === LolPlatformTeamId[side as keyof typeof LolPlatformTeamId]
    )
  }
  return side === 'blue' ? 0 : 1
}

export const valorantAgentByGuid = (guid: string) => {
  const agent
    = guidToAgentMap[guid.toLocaleLowerCase() as keyof typeof guidToAgentMap]
  return agent ? agent.name : ''
}
