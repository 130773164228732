/* eslint-disable max-lines */
import {
  AccountGroup,
  AccountsBatch,
  NameSource,
  TournamentRealmAccount,
  TournamentRealmAccountStatus
} from '@riotgames/api-types/elds/Accounts.type'
import {
  LivestatsStatus,
  LocalizedStringsWithSlug,
  RecordStatus,
  Sport,
  TournamentRealmOperationGrouped,
  TournamentRealmOperationInfo
} from '@riotgames/api-types/elds/Common.type'
import { MediaLocale } from '@riotgames/api-types/elds/Internal.type'
import { League } from '@riotgames/api-types/elds/Leagues.type'
import { Game, Match } from '@riotgames/api-types/elds/Matches.type'
import {
  GameVods,
  Stream,
  StreamGroups,
  Vods
} from '@riotgames/api-types/elds/Media.type'
import { Organization } from '@riotgames/api-types/elds/Organizations.type'
import {
  Player,
  PlayersResponse
} from '@riotgames/api-types/elds/Players_v3.type'
import {
  Broadcast,
  BroadcastItem,
  BroadcastStreamStatus,
  BroadcastStreams,
  BroadcastStreamsBundle,
  Schedule,
  ScheduleBlock,
  SchedulesResponse
} from '@riotgames/api-types/elds/Schedules_v3.type'
import { Season, Split } from '@riotgames/api-types/elds/Seasons.type'
import { Team } from '@riotgames/api-types/elds/Teams.type'
import { Timespan } from '@riotgames/api-types/elds/Timeline.type'
import {
  LineupEntry,
  MatchConfig,
  Section,
  Tournament,
  TournamentSchemaType,
  TournamentStage,
  TournamentStatus
} from '@riotgames/api-types/elds/Tournaments.type'
import { intersection } from 'lodash'
import { AnyAction, Reducer, combineReducers } from 'redux'
import { createSelector } from 'reselect'
import {
  assertNever,
  getLocalizedName,
  getSafe,
  keyById,
  omit,
  sortBy,
  times
} from '../../commons'
import { normalizeMatch } from '../../commons/MatchUtil/MatchUtil'
import { createOption } from '../../components'
import { DropdownOption } from '../../components/Dropdown/Dropdown.type'
import { SeasonFilter } from '../../containers/Season/List/SeasonList'
import { TournamentFilter } from '../../containers/Tournament/List/TournamentList'
import { Action, AnyPayloadAction, PayloadAction } from '../../store/Store.type'
import { createSlice, isLoading } from '../../store/actions/utils'
import {
  addScheduleV3,
  archiveSeason,
  createAccountGroup,
  createAccounts,
  createNewSeason,
  createNewTournament,
  deleteAccount,
  deleteAccounts,
  deleteScheduleV3,
  deleteStreamDetail,
  deleteStreamGroup,
  deleteVod,
  editAccount,
  editAccountGroup,
  editSeason,
  getAccountGroupsByIds,
  getAccountGroupsByName,
  getAccountsByIds,
  getAccountsPaginated,
  getAccountsUnionPaginated,
  getAllAccountGroups,
  getAllAccounts,
  getAllLeagues,
  getAllOrganizations,
  getAllPlayers,
  getAllPlayersPaginated,
  getAllPlayersUnionPaginated,
  getAllSchedules,
  getAllSeasons,
  getAllStreamGroups,
  getAllStreams,
  getAllTeams,
  getAllTournaments,
  getAllTournamentsCached,
  getAllTournamentsSummary,
  getAllVods,
  getAllVodsDetails,
  getAvailableVodsDetailsForGames,
  getMatches,
  getMatchesById,
  getScheduleByTournamentId,
  getSupportedLocales,
  getSupportedMediaLocales,
  getSupportedRegions,
  getTeamsById,
  getTimespansByPlatformGameId,
  getTournamentRealmOperationsFor,
  getTournamentsById,
  getUnscheduledByTournmentId,
  getVodsForGame,
  insertPlayer,
  insertStreamDetail,
  insertStreamGroup,
  insertVodsForGame,
  publishTournament,
  switchTeamColors,
  unarchiveSeason,
  updateGameResults,
  updateOrganization,
  updatePlayer,
  updateScheduleV3,
  updateStreamDetail,
  updateStreamGroup,
  updateTeam,
  updateTournament,
  updateVods
} from './Elds.actions'
import {
  AvailableVodsDetails,
  GameInfo,
  NormalizedMatch,
  UnscheduledTournamentMatches
} from './Elds.type'

// TODO: Remove one day if these leagues are ever supported
const leaguesToIgnoreForVods = new Set(['lpl'])

const keyByIdReducer = <T extends { id: string }>(
  state: Record<string, T>,
  { payload }: PayloadAction<T[]>
): Record<string, T> => keyById(payload)

const byIdReducer = <T extends { id: string }>(
  state: Record<string, T>,
  { payload }: PayloadAction<T>
): Record<string, T> => ({
    ...state,
    [payload.id]: payload
  })

const byMetaIdReducer = <T extends { id: string }>(
  state: Record<string, T>,
  { meta }: PayloadAction<null, T[]>
): Record<string, T> => {
  const [entity] = meta

  return {
    ...state,
    [entity.id]: entity
  }
}

const appendOrReplaceByIdReducer = <T extends { id: string }>(
  state: Record<string, T>,
  { payload }: PayloadAction<T[]>
) => ({
    ...state,
    ...keyById(payload)
  })

const omitByMetaIdReducer = <T extends string>(
  state: Record<string, T>,
  { meta }: PayloadAction<null, string>
) => omit(state, meta)

// #region Reducers
const accountSlice = createSlice({
  name: 'account',
  initialState: {} as Record<string, TournamentRealmAccount>,
  extraReducers: {
    [getAllAccounts.success.type]: keyByIdReducer,
    [getAccountsPaginated.success.type]: (
      state,
      { payload }: PayloadAction<AccountsBatch>
    ) => ({
      ...state,
      ...keyById(payload.accounts)
    }),
    [getAccountsUnionPaginated.success.type]: (
      state,
      { payload }: PayloadAction<AccountsBatch>
    ) => ({
      ...keyById(payload.accounts)
    }),
    [getAccountsByIds.success.type]: keyByIdReducer,
    [createAccounts.success.type]: appendOrReplaceByIdReducer,
    [editAccount.success.type]: appendOrReplaceByIdReducer,
    [deleteAccount.success.type]: omitByMetaIdReducer,
    [deleteAccounts.success.type]: omitByMetaIdReducer
  } as Record<
    string,
    Reducer<Record<string, TournamentRealmAccount>, AnyAction>
  >
})

const accountGroupSlice = createSlice({
  name: 'accountgroup',
  initialState: {} as Record<string, AccountGroup>,
  extraReducers: {
    [getAllAccountGroups.success.type]: (state, response) => ({
      ...state,
      ...keyById(response.payload.accountgroups)
    }),
    [getAccountGroupsByIds.success.type]: keyByIdReducer,
    [getAccountGroupsByName.success.type]: (state, response) => ({
      ...state,
      ...keyById(response.payload.accountgroups)
    }),
    [createAccountGroup.success.type]: byIdReducer,
    [editAccountGroup.success.type]: byMetaIdReducer
  } as Record<string, Reducer<Record<string, AccountGroup>, AnyAction>>
})

const leagueSlice = createSlice({
  name: 'league',
  initialState: {} as Record<string, League>,
  extraReducers: {
    [getAllLeagues.success.type]: keyByIdReducer
  } as Record<string, Reducer<Record<string, League>, AnyAction>>
})

const timespanSlice = createSlice({
  name: 'timespan',
  initialState: {} as Record<string, Timespan>,
  extraReducers: {
    [getTimespansByPlatformGameId.success.type]: keyByIdReducer
  } as Record<string, Reducer<Record<string, Timespan>, AnyAction>>
})

const playerSlice = createSlice({
  name: 'player',
  initialState: {} as Record<string, Player>,
  extraReducers: {
    [getAllPlayers.success.type]: keyByIdReducer,
    [getAllPlayersPaginated.success.type]: (
      state,
      { payload }: PayloadAction<PlayersResponse>
    ) => ({
      ...state,
      ...keyById(payload.players)
    }),
    [getAllPlayersUnionPaginated.success.type]: (
      state,
      { payload }: PayloadAction<PlayersResponse>
    ) => ({
      ...keyById(payload.players)
    }),
    [updatePlayer.success.type]: byMetaIdReducer
  } as Record<string, Reducer<Record<string, Player>, AnyAction>>
})

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState: {} as Record<string, ScheduleBlock>,
  extraReducers: {
    [getAllSchedules.success.type]: (
      state,
      { payload }: PayloadAction<SchedulesResponse>
    ) =>
      keyById(
        payload.schedules.flatMap(({ scheduleBlocks }) => scheduleBlocks)
      ),
    [getScheduleByTournamentId.success.type]: (
      state,
      { payload }: PayloadAction<Schedule[]>
    ) => ({
      ...state,
      ...keyById(payload.flatMap(({ scheduleBlocks }) => scheduleBlocks))
    }),
    [updateScheduleV3.success.type]: byMetaIdReducer,
    [addScheduleV3.success.type]: byIdReducer,
    [deleteScheduleV3.success.type]: omitByMetaIdReducer
  } as Record<string, Reducer<Record<string, ScheduleBlock>, AnyAction>>
})

const seasonSlice = createSlice({
  name: 'season',
  initialState: {} as Record<string, Season>,
  extraReducers: {
    [getAllSeasons.success.type]: keyByIdReducer,
    [createNewSeason.success.type]: byIdReducer,
    [archiveSeason.success.type]: byMetaIdReducer,
    [unarchiveSeason.success.type]: byMetaIdReducer,
    [editSeason.success.type]: byIdReducer
  } as Record<string, Reducer<Record<string, Season>, AnyAction>>
})

const streamSlice = createSlice({
  name: 'stream',
  initialState: {} as Record<string, Stream>,
  extraReducers: {
    [getAllStreams.success.type]: keyByIdReducer,
    [insertStreamDetail.success.type]: byIdReducer,
    [updateStreamDetail.success.type]: byMetaIdReducer,
    [deleteStreamDetail.success.type]: omitByMetaIdReducer
  } as Record<string, Reducer<Record<string, Stream>, AnyAction>>
})

const streamGroupsSlice = createSlice({
  name: 'streamGroup',
  initialState: {} as Record<string, StreamGroups>,
  extraReducers: {
    [getAllStreamGroups.success.type]: keyByIdReducer,
    [insertStreamGroup.success.type]: byIdReducer,
    [updateStreamGroup.success.type]: byMetaIdReducer,
    [deleteStreamGroup.success.type]: omitByMetaIdReducer
  } as Record<string, Reducer<Record<string, StreamGroups>, AnyAction>>
})

const teamSlice = createSlice({
  name: 'team',
  initialState: {} as Record<string, Team>,
  extraReducers: {
    [getAllTeams.success.type]: keyByIdReducer,
    [getTeamsById.success.type]: appendOrReplaceByIdReducer,
    [updateTeam.success.type]: byMetaIdReducer
  } as Record<string, Reducer<Record<string, Team>, AnyAction>>
})

// TODO: Finish normalizing by breaking tournament out into Tournament, Stage, and Section
const tournamentSlice = createSlice({
  name: 'tournament',
  initialState: {} as Record<string, Tournament>,
  extraReducers: {
    [getAllTournaments.success.type]: keyByIdReducer,
    [getAllTournamentsCached.success.type]: keyByIdReducer,
    [getTournamentsById.success.type]: appendOrReplaceByIdReducer,
    [getAllTournamentsSummary.success.type]: keyByIdReducer,
    [createNewTournament.success.type]: byIdReducer,
    [publishTournament.success.type]: byIdReducer,
    [updateTournament.success.type]: byIdReducer
  } as Record<string, Reducer<Record<string, Tournament>, AnyAction>>
})

const organizationSlice = createSlice({
  name: 'organization',
  initialState: {} as Record<string, Organization>,
  extraReducers: {
    [getAllOrganizations.success.type]: keyByIdReducer,
    [updateOrganization.success.type]: byMetaIdReducer
  } as Record<string, Reducer<Record<string, Organization>, AnyAction>>
})

const regionSlice = createSlice({
  name: 'regions',
  initialState: {} as Record<string, LocalizedStringsWithSlug>,
  extraReducers: {
    [getSupportedRegions.success.type]: (
      state: Record<string, LocalizedStringsWithSlug>,
      { payload }: PayloadAction<LocalizedStringsWithSlug[]>
    ) =>
      payload.reduce(
        (
          acc: Record<string, LocalizedStringsWithSlug>,
          region: LocalizedStringsWithSlug
        ) => ({
          ...acc,
          [region.slug]: region
        }),
        state
      )
  } as Record<
    string,
    Reducer<Record<string, LocalizedStringsWithSlug>, AnyPayloadAction>
  >
})

const localeSlice = createSlice({
  name: 'locales',
  initialState: [] as string[],
  extraReducers: {
    [getSupportedLocales.success.type]: (
      state: string[],
      { payload }: Action<string[]>
    ) => payload
  } as Record<string, Reducer<string[], AnyAction>>
})

const mediaLocaleSlice = createSlice({
  name: 'mediaLocale',
  initialState: [] as MediaLocale[],
  extraReducers: {
    [getSupportedMediaLocales.success.type]: (
      state: MediaLocale[],
      { payload }: Action<MediaLocale[]>
    ) => payload
  } as Record<string, Reducer<MediaLocale[], AnyAction>>
})

const matchSlice = createSlice({
  name: 'match',
  initialState: {} as Record<string, NormalizedMatch>,
  extraReducers: {
    [getMatchesById.success.type]: (
      state: Record<string, NormalizedMatch>,
      { payload }: PayloadAction<Match[]>
    ) => ({
      ...state,
      ...keyById(payload.map(normalizeMatch))
    }),
    [getMatches.success.type]: (
      state: Record<string, NormalizedMatch>,
      { payload }: PayloadAction<Match[]>
    ) => ({
      ...state,
      ...keyById(payload.map(normalizeMatch))
    })
  } as Record<string, Reducer<Record<string, NormalizedMatch>, AnyAction>>
})

const unscheduledMatchSlice = createSlice({
  name: 'match',
  initialState: {} as Record<string, BroadcastItem[]>,
  extraReducers: {
    [getUnscheduledByTournmentId.success.type]: (
      state: Record<string, BroadcastItem[]>,
      { payload }: PayloadAction<UnscheduledTournamentMatches[]>
    ) => ({
      ...payload.reduce(
        (
          acc: Record<string, BroadcastItem[]>,
          resp: { tournamentId: string; broadcastItems: BroadcastItem[] }
        ) => {
          acc[resp.tournamentId] = resp.broadcastItems

          return acc
        },
        state
      )
    })
  } as Record<string, Reducer<Record<string, BroadcastItem[]>, AnyAction>>
})

// Games are included on the ELDS response for Match, so we assemble this after the match thunk
const gameSlice = createSlice({
  name: 'game',
  initialState: {} as Record<string, Game>,
  extraReducers: {
    [getMatchesById.success.type]: (
      state: Record<string, Game>,
      { payload }: PayloadAction<Match[]>
    ) => ({
      ...state,
      ...keyById(payload.flatMap(({ games }) => games))
    }),
    [getMatches.success.type]: (
      state: Record<string, Game>,
      { payload }: PayloadAction<Match[]>
    ) => ({
      ...state,
      ...keyById(payload.flatMap(({ games }) => games))
    }),
    [switchTeamColors.success.type]: byMetaIdReducer,
    [updateGameResults.success.type]: byMetaIdReducer
  } as Record<string, Reducer<Record<string, Game>, AnyAction>>
})

const vodSlice = createSlice({
  name: 'vod',
  initialState: {} as Record<string, Vods>,
  extraReducers: {
    [getAllVods.success.type]: (
      state: Record<string, Vods>,
      { payload }: PayloadAction<GameVods[]>
    ) => keyById(payload.flatMap(({ vods }) => vods)),
    [getVodsForGame.success.type]: (
      state: Record<string, Vods>,
      { payload }: PayloadAction<GameVods[]>
    ) => ({
      ...state,
      ...keyById(payload.flatMap(({ vods }) => vods))
    }),
    [insertVodsForGame.success.type]: (
      state: Record<string, Vods>,
      { payload }: PayloadAction<GameVods[]>
    ) => ({
      ...state,
      ...keyById(payload.flatMap(({ vods }) => vods))
    }),
    [updateVods.success.type]: (
      state: Record<string, Vods>,
      { meta }: PayloadAction<null, GameVods[]>
    ) => ({
      ...state,
      ...keyById(meta.flatMap(({ vods }) => vods))
    }),
    [deleteVod.success.type]: omitByMetaIdReducer
  } as Record<string, Reducer<Record<string, Vods>, AnyAction>>
})

const vodsInGameSlice = createSlice({
  name: 'vodsInGame',
  initialState: {} as Record<string, string[]>,
  extraReducers: {
    [getAllVods.success.type]: (
      state: Record<string, string[]>,
      { payload }: PayloadAction<GameVods[]>
    ) => {
      const allMappings = payload.reduce(
        (acc, resp) => {
          acc[resp.gameId] = resp.vods.map((vod) => vod.id)

          return acc
        },
        {} as Record<string, string[]>
      )

      return {
        ...allMappings
      }
    },
    [getVodsForGame.success.type]: (
      state: Record<string, string[]>,
      { payload }: PayloadAction<GameVods[]>
    ) => {
      const updates = payload.reduce(
        (acc, resp) => {
          acc[resp.gameId] = resp.vods.map((vod) => vod.id)

          return acc
        },
        {} as Record<string, string[]>
      )

      return {
        ...state,
        ...updates
      }
    },
    [insertVodsForGame.success.type]: (
      state: Record<string, string[]>,
      { payload }: PayloadAction<GameVods[]>
    ) =>
      payload.reduce((acc, gameVods) => {
        const original = acc[gameVods.gameId] ?? []
        return {
          ...acc,
          [gameVods.gameId]: [
            ...original,
            ...gameVods.vods.map((vod) => vod.id)
          ]
        }
      }, state),
    [deleteVod.success.type]: (
      state: Record<string, string[]>,
      // This might have been a bug? The meta data is returned as an array.
      { meta }: PayloadAction<null, string[]>
    ) => {
      const withoutId = Object.entries(state).reduce(
        (acc: Record<string, string[]>, [gameId, vodIds]) => {
          const filtered = vodIds.filter((id) => id !== meta[0])
          // only include keys that actually have data
          if (filtered.length) {
            acc[gameId] = filtered
          }

          return acc
        },
        {}
      )

      return {
        ...withoutId
      }
    }
  } as Record<string, Reducer<Record<string, string[]>, AnyAction>>
})

const vodsDetailsSlice = createSlice({
  name: 'vodsDetails',
  initialState: {} as Record<string, Vods[]>,
  extraReducers: {
    [getAllVodsDetails.success.type]: (
      state: Record<string, Vods[]>,
      { payload }: PayloadAction<GameVods[]>
    ) =>
      payload.reduce(
        (acc, gameVods) => ({
          ...acc,
          [gameVods.gameId]: gameVods.vods
        }),
        state
      ),
    [getAvailableVodsDetailsForGames.success.type]: (
      state: Record<string, Vods[]>,
      { payload }: PayloadAction<GameVods[]>
    ) =>
      payload.reduce(
        (acc, gameVods) => ({
          ...acc,
          [gameVods.gameId]: gameVods.vods
        }),
        state
      ),
    [getAllVods.success.type]: (
      state: Record<string, Vods[]>,
      { payload }: PayloadAction<GameVods[]>
    ) => {
      /*
       *  Improves computation time from 33sec to < 0.003s
       *   over using reduce/spread operations.
       *  Note: DO NOT REFACTOR. Although this coding
       *   style is avoided through, we explicitly
       *   need performance optimizations for this
       *   method.
       */
      const newState = { ...state }
      payload.forEach(({ gameId, vods }) => {
        newState[gameId] = vods
      })
      return newState
    },
    [getVodsForGame.success.type]: (
      state: Record<string, Vods[]>,
      { payload }: PayloadAction<GameVods[]>
    ) => {
      const updates = payload.reduce(
        (acc, resp) => {
          acc[resp.gameId] = resp.vods

          return acc
        },
        {} as Record<string, Vods[]>
      )

      return {
        ...state,
        ...updates
      }
    },
    [insertVodsForGame.success.type]: (
      state: Record<string, Vods[]>,
      { payload }: PayloadAction<GameVods[]>
    ) =>
      payload.reduce((acc, gameVods) => {
        const original = acc[gameVods.gameId] ?? []
        return {
          ...acc,
          [gameVods.gameId]: [...original, ...gameVods.vods]
        }
      }, state),
    [updateVods.success.type]: (
      state: Record<string, Vods[]>,
      { meta }: PayloadAction<null, GameVods[]>
    ) =>
      meta.reduce((acc, gameVods) => {
        const originals = acc[gameVods.gameId] ?? []
        return {
          ...acc,
          [gameVods.gameId]: originals.map(
            (original) =>
              gameVods.vods.find((update) => update.id === original.id)
              ?? original
          )
        }
      }, state),
    [deleteVod.success.type]: (
      state: Record<string, Vods[]>,
      { meta }: PayloadAction<null, string[]>
    ) => {
      const withoutId = Object.entries(state).reduce(
        (acc, [gameId, vods]) => {
          const filtered = vods.filter((vod) => vod.id !== meta[0])
          // only include keys that actually have data
          if (filtered.length) {
            acc[gameId] = filtered
          }

          return acc
        },
        {} as Record<string, Vods[]>
      )

      return {
        ...withoutId
      }
    }
  } as Record<string, Reducer<Record<string, Vods[]>, AnyPayloadAction>>
})

const accountOperationsSlice = createSlice({
  name: 'freeFloatingAccountOperations',
  initialState: {} as Record<string, TournamentRealmOperationInfo[]>,
  extraReducers: {
    [getTournamentRealmOperationsFor.success.type]: (
      state: Record<string, TournamentRealmOperationInfo[]>,
      { payload }: PayloadAction<TournamentRealmOperationGrouped[]>
    ) => {
      const updates = payload.reduce(
        (acc, resp) => {
          acc[resp.accountId] = resp.realmOperations.map((op) => op)

          return acc
        },
        {} as Record<string, TournamentRealmOperationInfo[]>
      )

      return {
        ...state,
        ...updates
      }
    }
  } as Record<
    string,
    Reducer<{ [x: string]: TournamentRealmOperationInfo[] }, AnyPayloadAction>
  >
})

const isLoadingReducer = combineReducers({
  isAccountLoading: isLoading(getAllAccounts),
  isAccountPaginatedLoading: isLoading(getAccountsPaginated),
  isAccountByIdsLoading: isLoading(getAccountsByIds),
  isAccountUnionPaginatedLoading: isLoading(getAccountsUnionPaginated),
  isAccountGroupsLoading: isLoading(getAllAccountGroups),
  isTimelineLoading: isLoading(getTimespansByPlatformGameId, false),
  isLeagueLoading: isLoading(getAllLeagues, false),
  isPlayerLoading: isLoading(getAllPlayers),
  isInsertPlayerLoading: isLoading(insertPlayer, false),
  isPlayerPaginatedLoading: isLoading(getAllPlayersPaginated),
  isPlayerUnionPaginatedLoading: isLoading(getAllPlayersUnionPaginated),
  isScheduleLoading: isLoading(getAllSchedules),
  isUnscheduledLoading: isLoading(getUnscheduledByTournmentId),
  isSeasonLoading: isLoading(getAllSeasons),
  isStreamLoading: isLoading(getAllStreams),
  isStreamGroupsLoading: isLoading(getAllStreamGroups),
  isTeamLoading: isLoading(getAllTeams),
  isTeamsByIdLoading: isLoading(getTeamsById),
  isTournamentLoading: isLoading(getAllTournaments),
  isTournamentCachedLoading: isLoading(getAllTournamentsCached),
  isTournamentsByIdLoading: isLoading(getTournamentsById, false),
  isTournamentsSummaryLoading: isLoading(getAllTournamentsSummary),
  isOrganizationLoading: isLoading(getAllOrganizations),
  isCreateTournamentLoading: isLoading(createNewTournament, false),
  isPublishTournamentLoading: isLoading(publishTournament, false),
  isUpdateTournamentLoading: isLoading(updateTournament, false),
  isSupportedRegionsLoading: isLoading(getSupportedRegions),
  isSupportedLocalesLoading: isLoading(getSupportedLocales),
  isCreateSeasonLoading: isLoading(createNewSeason, false),
  isCreateAccountsLoading: isLoading(createAccounts, false),
  isEditAccountLoading: isLoading(editAccount, false),
  isSupportedMediaLocalesLoading: isLoading(getSupportedMediaLocales, false),
  isMatchLoading: isLoading(getMatchesById),
  isGameLoading: isLoading(getMatchesById, false),
  isVodLoading: isLoading(getVodsForGame, false),
  isAvailableVodsForGamesLoading: isLoading(
    getAvailableVodsDetailsForGames,
    false
  ),
  isScheduleForTournamentLoading: isLoading(getScheduleByTournamentId, false),
  isUnScheduleForTournamentLoading: isLoading(
    getUnscheduledByTournmentId,
    false
  ),
  isVodsDetailsLoading: isLoading(getAllVodsDetails),
  isAccountOperationsLoading: isLoading(getTournamentRealmOperationsFor),
  isMatchesLoading: isLoading(getMatches)
})

const combinedReducer = combineReducers({
  account: accountSlice.reducer,
  accountGroup: accountGroupSlice.reducer,
  league: leagueSlice.reducer,
  timespan: timespanSlice.reducer,
  player: playerSlice.reducer,
  schedule: scheduleSlice.reducer,
  season: seasonSlice.reducer,
  stream: streamSlice.reducer,
  streamGroups: streamGroupsSlice.reducer,
  team: teamSlice.reducer,
  tournament: tournamentSlice.reducer,
  organization: organizationSlice.reducer,
  region: regionSlice.reducer,
  locale: localeSlice.reducer,
  mediaLocale: mediaLocaleSlice.reducer,
  match: matchSlice.reducer,
  unscheduledMatch: unscheduledMatchSlice.reducer,
  game: gameSlice.reducer,
  vod: vodSlice.reducer,
  vodsInGame: vodsInGameSlice.reducer,
  vodsDetails: vodsDetailsSlice.reducer,
  accountOperations: accountOperationsSlice.reducer,
  isLoading: isLoadingReducer
})

export default combinedReducer

// #endregion

// #region Selectors

type StateShape = ReturnType<typeof combinedReducer>

type HasSport = { sport: Sport }
const filterBySport = <T extends HasSport>(
  record: Record<string, T>,
  sport: Sport
): Record<string, T> =>
    keyById(Object.values(record).filter((item) => item.sport === sport))

// #region Accounts
export const getAccounts = createSelector(
  [
    (state: StateShape) => state.account,
    (_: StateShape, sport: Sport) => sport
  ],
  (accounts, sport) => {
    const isAccountForSelectedSport = (account: TournamentRealmAccount) =>
      account.enabledSports.includes(sport)
    const isAccountActive = (account: TournamentRealmAccount) =>
      account.status === TournamentRealmAccountStatus.Active
    const isAccountFreeFloating = (account: TournamentRealmAccount) =>
      account.nameSource === NameSource.FromAccount

    return Object.values(accounts).filter(
      (account) =>
        isAccountForSelectedSport(account)
        && isAccountActive(account)
        && isAccountFreeFloating(account)
    )
  }
)

export const getDuplicatedAccountsByHandles = createSelector(
  [
    (state: StateShape) => state.account,
    (_: StateShape, handles: string[]) => handles
  ],
  (accounts, handles) => {
    const allHandles = handles.map((h) => h?.toLowerCase())
    const allDisplayName = Object.values(accounts).map((a) =>
      a.displayName?.current?.toLowerCase()
    )
    const dupes = intersection(allHandles, allDisplayName).map((i) => i)
    return dupes
  }
)

export const getDuplicatedAccountsByIds = createSelector(
  [
    (state: StateShape) => state.account,
    (_: StateShape, ids: string[]) => ids
  ],
  (accounts, ids) => {
    const allIds = ids.map((id) => id)
    const allAccountIds = Object.values(accounts).map((a) => a.id)
    // Return account details keyed by id for the duplicated ids
    const dupeAccounts = intersection(allIds, allAccountIds).map((i) => accounts[i])
    return dupeAccounts.reduce((acc, account) => {
      acc[account.id] = account
      return acc
    }, {})
  }
)

export const getAccountById = createSelector(
  [(state: StateShape) => state.account, (_: StateShape, id: string) => id],
  (accounts, id) => accounts[id]
)

export const getAccountInMemoryById = createSelector(
  [(state: StateShape) => state.account, (_: StateShape, id: string) => id],
  (accounts, id) => accounts[id]
)

export const getAccountOperationsById = (state: StateShape, id: string) =>
  state.accountOperations[id] || []

export const getAccountOperationsByIdReversedChronological = createSelector(
  [getAccountOperationsById],
  (accountOperations) => accountOperations.reverse()
)

export const getAccountGroups = (
  state: StateShape
): Record<string, AccountGroup> => state.accountGroup

export const getAccountGroupById = (
  state: StateShape,
  id: string
): AccountGroup => state.accountGroup[id]

export const getAccountGroupOptions = createSelector(
  [getAccountGroups],
  (groups: Record<string, AccountGroup>) =>
    Object.values(groups)
      .map((group) => createOption(group.name, group.id))
      .sort(sortBy('label'))
)

// #endregion

// #region Timeline
export const getTimespans = (state: StateShape): Record<string, Timespan> =>
  state.timespan
// #endregion

// #region Leagues
export const getLeagues = (
  state: StateShape,
  sport: Sport
): Record<string, League> => filterBySport(state.league, sport)

export const getUnfilteredLeagues = (
  state: StateShape
): Record<string, League> => state.league

export const getLeagueById = (state: StateShape, id: string): League =>
  state.league[id]

export const getLeagueOptions = (
  state: StateShape,
  sport: Sport
): DropdownOption[] =>
  Object.values(getLeagues(state, sport))
    .filter((league) => league.status === RecordStatus.Active)
    .map((league) =>
      createOption(
        getLocalizedName(league.name),
        league.id,
        league.lightLogoUrl
      )
    )
    .sort(sortBy('label'))

// #endregion

// #region Players
export const getPlayers = (
  state: StateShape,
  sport: Sport
): Record<string, Player> => filterBySport(state.player, sport)

export const getPlayerById = (state: StateShape, id: string): Player =>
  state.player[id]

export const getDuplicatedPlayersByHandles = createSelector(
  [
    (state: StateShape) => state.player,
    (_: StateShape, handles: string[]) => handles
  ],
  (players, handles) => {
    const allHandles = handles.map((h) => h?.toLowerCase())
    const playerHandles = Object.values(players).map((player) =>
      player.handle.toLowerCase()
    )

    return intersection(allHandles, playerHandles).map((i) => i)
  }
)

export const getPlayersOptions = (
  state: StateShape,
  sport: Sport
): DropdownOption[] =>
  Object.values(getPlayers(state, sport))
    .map((player) => createOption(player.handle, player.id, player.photoUrl))
    .sort(sortBy('label'))

// #endregion

// #region Schedules
export const getSchedules = (
  state: StateShape
): Record<string, ScheduleBlock> => state.schedule

export const getScheduleById = (state: StateShape, id: string): ScheduleBlock =>
  state.schedule[id]

export const getScheduleByTournament = createSelector(
  [(state: StateShape) => state.schedule, (_: StateShape, id: string) => id],
  (schedules, id) =>
    // Note from QA: Sometimes the currently live set of match's/broadcast
    // will appear on the top of the list
    // Fix: Override the order to guarantee it comes out as expected
    Object.values(schedules)
      .filter((block) => block.tournamentId === id)
      .sort(
        (a, b) =>
          new Date(a.broadcasts[0]?.broadcastItems[0]?.startTime).getTime()
          - new Date(b.broadcasts[0]?.broadcastItems[0]?.startTime).getTime()
      )
)

export const getFilteredTournamentList = createSelector(
  [
    (state: StateShape, sport: Sport) => getTournaments(state, sport),
    (state: StateShape, sport: Sport) => getLeagues(state, sport),
    (state: StateShape, sport: Sport, filter: TournamentFilter) => filter
  ],
  (tournaments, leagues, filter) =>
    Object.values(tournaments)
      // Filter tournaments which don't match current selection
      .filter(
        (tournament) =>
          filter === 'current'
            && tournament.status === TournamentStatus.Published
          || filter === 'old'
            && tournament.status === TournamentStatus.Published
          || filter === 'archived'
            && tournament.status === TournamentStatus.Archived
          || filter === 'draft' && tournament.status === TournamentStatus.Draft
      )
      // Filter out archived leagues
      .filter((tournament) => {
        const league = leagues[tournament.leagueId]
        return league && league.status === RecordStatus.Active
      })
      // Filter tournaments based on their end date when on Current or Old views
      .filter((tournament) => {
        if (filter !== 'old' && filter !== 'current') {
          return true
        }

        const today = new Date()
        const endDate = new Date(tournament.endTime)
        const endDateWithBuffer = new Date(endDate.getTime() + 14 * times.DAY)

        return filter === 'current'
          ? today < endDateWithBuffer
          : today > endDateWithBuffer
      })
      .map((tournament) => ({
        name: getLocalizedName(tournament.name),
        league: tournament.leagueId,
        id: tournament.id
      }))
)

export const getBroadcastItemsByTournament = createSelector(
  [getScheduleByTournament],
  (scheduleBlocks: ScheduleBlock[]) =>
    scheduleBlocks.flatMap((block) =>
      block.broadcasts.flatMap((broadcast) => broadcast.broadcastItems)
    )
)

export const getScheduleMatchesByTournament = createSelector(
  [getBroadcastItemsByTournament],
  (broadcastItems) =>
    broadcastItems.flatMap((broadcastItem) => broadcastItem.matchId)
)

export const getUnscheduleByTournament = (
  state: StateShape,
  id: string
): BroadcastItem[] => state.unscheduledMatch[id]

// #endregion

// #region Seasons
export const getSeasons = (
  state: StateShape,
  sport: Sport
): Record<string, Season> => filterBySport(state.season, sport)

export const getSeasonById = (state: StateShape, id: string): Season =>
  state.season[id]

/**
 * Returns a list of seasons based on the provided SeasonFilter.
 * Filter:
 *  - **Current**: Active Seasons with an end date no more than two weeks ago
 *  - **Old**: Active Seasons with an end date more than two weeks ago
 *  - **Archived**: Archived Seasons
 */
export const getSeasonsByFilter = createSelector(
  [
    getSeasons,
    (state: StateShape, sport: Sport, filter: SeasonFilter) => filter
  ],
  (seasons, filter) => {
    const today = new Date()
    return Object.values(seasons).filter((season) => {
      const seasonEndDate = new Date(season.endTime)
      const endDateWithBuffer = new Date(
        seasonEndDate.getUTCFullYear(),
        seasonEndDate.getUTCMonth(),
        seasonEndDate.getUTCDate() + 14
      )

      const isTodayBeforeBuffer = today < endDateWithBuffer

      switch (filter) {
        case SeasonFilter.Current:
          return season.status === RecordStatus.Active && isTodayBeforeBuffer
        case SeasonFilter.Old:
          return season.status === RecordStatus.Active && !isTodayBeforeBuffer
        case SeasonFilter.Archived:
          return season.status === RecordStatus.Archived
        default:
          // Will produce a compile-time error if this switch does not handle
          // all possible SeasonFilters.  Ensures we know to update it if/when
          // we ever add a new one.
          return assertNever(filter)
      }
    })
  }
)

export const getSeasonOptions = createSelector(
  [getSeasons],
  (seasons: Record<string, Season>) =>
    Object.values(seasons)
      .filter((season) => season.status === RecordStatus.Active)
      .map((season) => createOption(getLocalizedName(season.name), season.id))
      .sort(sortBy('label'))
)

export const getSplitOptionsForSeason = createSelector(
  [getSeasonById],
  (season: Season) =>
    season
      ? season.splits
        .map((split) => createOption(getLocalizedName(split.name), split.id))
        .sort(sortBy('label'))
      : []
)

export const getSplitById = (
  state: StateShape,
  seasonId: string,
  splitId: string
): Split | undefined => {
  const season = getSeasonById(state, seasonId)

  return season
    ? season.splits.find((split) => split.id === splitId)
    : undefined
}

export const isSplitUsedByAnyTournament = createSelector(
  // Intentionally not using getTournaments() Selector since it filters by sport
  // Splits are not tied to a sport, so we need to make sure we compare against
  // all tournaments.
  [
    (state: StateShape) => state.tournament,
    (state: StateShape, splitId: string) => splitId
  ],
  (tournaments: Record<string, Tournament>, splitId: string) => {
    // If the split is null, this means we are checking a not-yet-created
    // split, so it cannot possibly be used by a tournament already.
    if (!splitId) return false

    // If there is not sufficient tournament information, we default to true
    if (Object.values(tournaments).length === 0) return true

    return Object.values(tournaments).some(
      (tournament) => tournament.splitId === splitId
    )
  }
)

// #endregion

// #region Sections
export const getSectionsByTournamentId = (
  state: StateShape,
  tournamentId: string
): Record<string, Section> =>
  state.tournament[tournamentId]?.stages
    .flatMap((stage) => stage.sections)
    .reduce(
      (acc, section) => {
        acc[section.id] = section
        return acc
      },
      {} as Record<string, Section>
    ) || {}
// #endregion

// #region Streams
export const getStreams = (state: StateShape): Record<string, Stream> =>
  state.stream
export const getStreamById = (state: StateShape, id: string): Stream =>
  state.stream[id]

export const getStreamToStatus = createSelector(
  [getScheduleByTournament],
  (scheduleBlocks: ScheduleBlock[]): Record<string, BroadcastStreamStatus> =>
    scheduleBlocks
      .flatMap((scheduleBlock: ScheduleBlock) => scheduleBlock.broadcasts)
      .filter((broadcast: Broadcast) => broadcast.inProgressInfo != null)
      .flatMap((broadcast: Broadcast) => broadcast.inProgressInfo.bundles)
      .flatMap((bundle: BroadcastStreamsBundle) =>
        Object.values(bundle.localizedStreams)
      )
      .flatMap((broadcastStreams: BroadcastStreams) => broadcastStreams.streams)
      .reduce(
        (acc, curr) => {
          acc[curr.id] = curr.status
          return acc
        },
        {} as Record<string, BroadcastStreamStatus>
      )
)

export const getStreamToStatsStatus = createSelector(
  [getScheduleByTournament],
  (scheduleBlocks: ScheduleBlock[]): Record<string, LivestatsStatus> =>
    scheduleBlocks
      .flatMap((scheduleBlock: ScheduleBlock) => scheduleBlock.broadcasts)
      .filter((broadcast: Broadcast) => broadcast.inProgressInfo != null)
      .flatMap((broadcast: Broadcast) => broadcast.inProgressInfo.bundles)
      .flatMap((bundle: BroadcastStreamsBundle) =>
        Object.values(bundle.localizedStreams)
      )
      .flatMap((broadcastStreams: BroadcastStreams) => broadcastStreams.streams)
      .reduce(
        (acc, curr) => {
          acc[curr.id] = curr.statsStatus
          return acc
        },
        {} as Record<string, LivestatsStatus>
      )
)

export const getStreamToOffset = createSelector(
  [getScheduleByTournament],
  (scheduleBlocks: ScheduleBlock[]): Record<string, number> =>
    scheduleBlocks
      .flatMap((scheduleBlock: ScheduleBlock) => scheduleBlock.broadcasts)
      .filter((broadcast: Broadcast) => broadcast.inProgressInfo != null)
      .flatMap((broadcast: Broadcast) => broadcast.inProgressInfo.bundles)
      .flatMap((bundle: BroadcastStreamsBundle) =>
        Object.values(bundle.localizedStreams)
      )
      .flatMap((broadcastStreams: BroadcastStreams) => broadcastStreams.streams)
      .reduce(
        (acc, curr) => {
          acc[curr.id] = curr.statsOffsetMillis
          return acc
        },
        {} as Record<string, number>
      )
)
// #endregion

// #region Stream Groups
export const getStreamGroups = (
  state: StateShape
): Record<string, StreamGroups> => state.streamGroups
export const getStreamGroupById = (
  state: StateShape,
  id: string
): StreamGroups => state.streamGroups[id]

export const getStreamGroupOptions = (state: StateShape): DropdownOption[] =>
  Object.values(getStreamGroups(state))
    .map((streamGroup) => createOption(streamGroup.name, streamGroup.id))
    .sort(sortBy('label'))

// #endregion

// #region Teams
export const getTeams = (
  state: StateShape,
  sport: Sport
): Record<string, Team> => filterBySport(state.team, sport)

export const getUnfilteredTeams = (state: StateShape): Record<string, Team> =>
  state.team

export const getTeamOptions = (
  state: StateShape,
  sport: Sport
): DropdownOption[] =>
  Object.values(getTeams(state, sport))
    .filter((team) => team.status === RecordStatus.Active)
    .map((team) => createOption(team.name, team.id, team.lightLogoUrl))
    .sort(sortBy('label'))

export const getTeamById = (state: StateShape, id: string): Team =>
  state.team[id]

// #endregion

// #region Tournaments
export const getTournaments = (
  state: StateShape,
  sport: Sport
): Record<string, Tournament> => filterBySport(state.tournament, sport)

export const getTournamentById = (state: StateShape, id: string): Tournament =>
  state.tournament[id]

export const getTournamentOptions = (
  state: StateShape,
  sport: Sport
): DropdownOption[] =>
  Object.values(getTournaments(state, sport))
    .filter(
      (tournament: Tournament) =>
        tournament.schemaType !== TournamentSchemaType.LegacyImport
    )
    .map((tournament: Tournament) =>
      createOption(
        getLocalizedName(tournament.name),
        tournament.id,
        getLeagueById(state, tournament.leagueId).lightLogoUrl
      )
    )
    .sort(sortBy('label'))

export const getTeamsInTournament = createSelector(
  [getTournamentById, (state: StateShape) => state],
  (tournament, state) =>
    tournament.seeding.entries
      .filter((entry: LineupEntry) => entry.teamId != null)
      .reduce(
        (teamMap, { teamId }) => {
          const team = getTeamById(state, teamId)
          if (team) teamMap[teamId] = team
          return teamMap
        },
        {} as Record<string, Team>
      )
)

export const getPlayersInTournament = createSelector(
  [getTournamentById, (state: StateShape) => state],
  (tournament, state) => {
    const teams: Record<string, Team> = getTeamsInTournament(
      state,
      tournament.id
    )
    return Object.values(teams)
      .flatMap(({ roster }) => roster)
      .filter((roster) => roster.playerId != null)
      .reduce((playersMap: Record<string, Player>, { playerId }) => {
        const player = getPlayerById(state, playerId)
        if (player) playersMap[playerId] = player
        return playersMap
      }, {})
  }
)

export const getPlayerIdsInTournament = createSelector(
  [getTournamentById, (state: StateShape) => state],
  (tournament, state) => {
    const teams: Record<string, Team> = getTeamsInTournament(
      state,
      tournament.id
    )
    return Object.values(teams)
      .flatMap(({ roster }) => roster)
      .map((roster) => roster.playerId)
  }
)

// #endregion

// #region Organizations
export const getOrganizations = (
  state: StateShape
): Record<string, Organization> => state.organization

export const getOrganizationById = (
  state: StateShape,
  id: string
): Organization => state.organization[id]

// #endregion

// #region Regions
export const getRegions = (state: StateShape) => state.region

export const getRegionBySlug = (
  state: StateShape,
  slug: string
): LocalizedStringsWithSlug => getRegions(state)[slug]

export const getRegionOptions = (state: StateShape): DropdownOption[] =>
  [createOption('Select a Region', null)].concat(
    Object.values(getRegions(state)).map((region) =>
      createOption(getLocalizedName(region), region.slug)
    )
  )
// #endregion

// #region Locales
export const getLocales = (state: StateShape): string[] => state.locale

export const getMediaLocales = (state: StateShape): MediaLocale[] =>
  state.mediaLocale

export const getMediaLocaleOptions = (state: StateShape): DropdownOption[] =>
  [createOption('Select a Locale', null)].concat(
    getMediaLocales(state).map((mediaLocale) =>
      createOption(mediaLocale.locale, mediaLocale.locale)
    )
  )
// #endregion

// #region Matches
export const getAllMatches = (
  state: StateShape
): Record<string, NormalizedMatch> => state.match

export const getMatchByIds = (state: StateShape, matchIds: string[]) =>
  matchIds.map((id) => getMatchById(state, id)).filter((match) => match)

export const getMatchById = (state: StateShape, id: string): NormalizedMatch =>
  state.match[id]

export const getMatchesByTournamentId = (
  state: StateShape,
  tournamentId: string
): Record<string, NormalizedMatch> =>
  state.tournament[tournamentId]?.stages
    .flatMap((stage) => stage.sections)
    .flatMap((section) => section.matches)
    .reduce(
      (acc, match) => {
        acc[match.id] = state.match[match.id]
        return acc
      },
      {} as Record<string, NormalizedMatch>
    ) || {}
// #endregion

// #region Games
export const getGameById = (state: StateShape, id: string): Game =>
  state.game[id]

export const getGamesForMatch = (state: StateShape, id: string): Game[] =>
  getMatchById(state, id).gameIds.map((gameId) => state.game[gameId])
// #endregion

// #region VODs
export const getVodById = (state: StateShape, id: string): Vods => state.vod[id]

export const getVodsByGameId = (state: StateShape, id: string): Vods[] =>
  getSafe(
    // We need to cast to fix TS2345 where typescript thinks state.vodsInGame is an object
    state.vodsInGame as Record<string, string[]>,
    id,
    []
  ).map((vodId: string) => getVodById(state, vodId))
// #endregion

// #region VODs Details
export const getVodsDetails = (state: StateShape) => state.vodsDetails

export const getVodsDetailsById = createSelector(
  [(state: StateShape) => state.vodsDetails, (_: StateShape, id: string) => id],
  (vodsDetails, id) => vodsDetails[id]
)

export const getCompletedMatches = createSelector(
  [getSchedules],
  (schedules: Record<string, ScheduleBlock>): string[] =>
    Object.values(schedules)
      .flatMap((schedule: ScheduleBlock) => schedule.broadcasts)
      .flatMap((broadcast: Broadcast) => broadcast.broadcastItems)
      .filter(
        (broadcastItem: BroadcastItem) => broadcastItem.status === 'completed'
      )
      .map((broadcastItem: BroadcastItem) => broadcastItem.matchId)
)

export const getBroadcastForMatchId = createSelector(
  [getSchedules],
  (schedules: Record<string, ScheduleBlock>): Record<string, Broadcast> => {
    const matchIdsToBroadcast: Record<string, Broadcast> = {}
    Object.values(schedules)
      .flatMap((schedule: ScheduleBlock) => schedule.broadcasts)
      .forEach((broadcast: Broadcast) => {
        broadcast.broadcastItems.map((broadcastItem: BroadcastItem) => {
          matchIdsToBroadcast[broadcastItem.matchId] = broadcast
        })
      })

    return matchIdsToBroadcast
  }
)

export const getTournamentForMatchId = (
  tournaments: Record<string, Tournament>,
  matchIds: string[]
): Record<string, Tournament> => {
  const matchSet = new Set(matchIds)
  const matchToTournament: Record<string, Tournament> = {}
  Object.values(tournaments).forEach((tournament: Tournament) => {
    tournament.stages
      .flatMap((stage: TournamentStage) => stage.sections)
      .flatMap((section: Section) => section.matches)
      .filter((matchConfig: MatchConfig) => matchSet.has(matchConfig.id))
      .forEach((matchConfig: MatchConfig) => {
        matchToTournament[matchConfig.id] = tournament
      })
  })
  return matchToTournament
}

export const getLeagueForMatchId = (
  leagues: Record<string, League>,
  matchToTournament: Record<string, Tournament>
): Record<string, League> =>
  Object.entries(matchToTournament).reduce(
    (acc, [matchId, tournament]) => {
      acc[matchId] = leagues[tournament.leagueId]
      return acc
    },
    {} as Record<string, League>
  )

export const getGameIdsForMatch = (
  state: StateShape,
  matchIds: string[]
): Record<string, string[]> =>
  matchIds
    .map((matchId: string) => getMatchById(state, matchId))
    .filter((match: NormalizedMatch) => match)
    .reduce(
      (acc, curr) => {
        acc[curr.id] = curr.gameIds
        return acc
      },
      {} as Record<string, string[]>
    )

export const getGamesForMatches = createSelector(
  [
    (state: StateShape) => state,
    (_: StateShape, matchIds: string[]) => matchIds
  ],
  (state, matchIds) =>
    matchIds.reduce(
      (acc, matchId: string) => {
        const match = getMatchById(state, matchId)
        if (match) {
          acc[match.id] = match.gameIds.map((gameId) =>
            getGameById(state, gameId)
          )
        }
        return acc
      },
      {} as Record<string, Game[]>
    )
)

export const getTeamsForMatches = createSelector(
  [
    (state: StateShape) => state,
    (_: StateShape, matchIds: string[]) => matchIds
  ],
  (state, matchIds) =>
    matchIds.reduce(
      (acc, matchId: string) => {
        const match = getMatchById(state, matchId)
        if (match) {
          acc[match.id] = match.participatingTeams.map((teamId) =>
            getTeamById(state, teamId)
          )
        }
        return acc
      },
      {} as Record<string, Team[]>
    )
)

export const getGamesToTeams = (state: StateShape, gameIds: string[]) =>
  gameIds
    .map((gameId: string) => getGameById(state, gameId))
    .filter((game: Game) => game && game.teamOutcomes)
    .reduce(
      (acc, curr: Game) => {
        acc[curr.id] = {
          gameNumber: curr.number,
          blueTeamName: getTeamById(state, curr.blueTeam)?.tricode,
          redTeamName: getTeamById(state, curr.redTeam)?.tricode
        }
        return acc
      },
      {} as Record<string, GameInfo>
    )

export const getAvailableVodsDetails = createSelector(
  [(state: StateShape) => state, (_: StateShape, sport: Sport) => sport],
  (state: StateShape, sport: Sport) => {
    // TODO: remove one day when val/wr supported for livestats
    if (sport !== Sport.Lol) return {}

    // Get all the matches
    const matchIds: string[] = getCompletedMatches(state)

    // Get broadcast id for each match
    const matchToBroadcast: Record<string, Broadcast>
      = getBroadcastForMatchId(state)

    // Get the tournament from the match id
    const matchToTournament: Record<string, Tournament>
      = getTournamentForMatchId(getTournaments(state, sport), matchIds)

    // Get the league information from the match id
    const matchToLeague: Record<string, League> = getLeagueForMatchId(
      getLeagues(state, sport),
      matchToTournament
    )

    // Get the gameId from matches
    const matchToGameIds: Record<string, string[]> = getGameIdsForMatch(
      state,
      matchIds
    )

    // Get team information for each game
    const gamesToTeams = getGamesToTeams(
      state,
      Object.values(matchToGameIds).flatMap((gameIds) => gameIds)
    )

    // Get the VODs for each game and return all the relevant information pertaining to each game
    const availableVodsDetails: Record<string, AvailableVodsDetails> = {}
    Object.entries(matchToGameIds).forEach(
      ([matchId, gameIds]: [string, string[]]) => {
        gameIds.forEach((gameId: string) => {
          const vodsDetails = getVodsDetailsById(state, gameId)

          // Skip any games that have not completed
          if (
            vodsDetails
            && gamesToTeams[gameId]
            && matchToTournament[matchId]
            && matchToLeague[matchId]
            && !leaguesToIgnoreForVods.has(matchToLeague[matchId].name.slug)
          ) {
            availableVodsDetails[gameId] = {
              vodsDetails: vodsDetails,
              tournament: matchToTournament[matchId],
              league: matchToLeague[matchId],
              gameInfo: gamesToTeams[gameId],
              broadcast: matchToBroadcast[matchId],
              matchId: matchId,
              gameId: gameId
            }
          }
        })
      }
    )

    return availableVodsDetails
  }
)
// #endregion

// #region isLoading
export const isAccountLoading = (state: StateShape): boolean =>
  state.isLoading.isAccountLoading

export const isAccountByIdsLoading = (state: StateShape): boolean =>
  state.isLoading.isAccountByIdsLoading

export const isAccountPaginatedLoading = (state: StateShape): boolean =>
  state.isLoading.isAccountPaginatedLoading

export const isAccountUnionPaginatedLoading = (state: StateShape): boolean =>
  state.isLoading.isAccountUnionPaginatedLoading

export const isEditAccountLoading = (state: StateShape): boolean =>
  state.isLoading.isEditAccountLoading

export const isLeagueLoading = (state: StateShape): boolean =>
  state.isLoading.isLeagueLoading

export const isPlayerLoading = (state: StateShape): boolean =>
  state.isLoading.isPlayerLoading

export const isInsertPlayerLoading = (state: StateShape): boolean =>
  state.isLoading.isInsertPlayerLoading

export const isPlayerPaginatedLoading = (state: StateShape): boolean =>
  state.isLoading.isPlayerPaginatedLoading

export const isPlayerUnionPaginatedLoading = (state: StateShape): boolean =>
  state.isLoading.isPlayerUnionPaginatedLoading

export const isScheduleLoading = (state: StateShape): boolean =>
  state.isLoading.isScheduleLoading

export const isSeasonLoading = (state: StateShape): boolean =>
  state.isLoading.isSeasonLoading

export const isStreamLoading = (state: StateShape): boolean =>
  state.isLoading.isStreamLoading

export const isStreamGroupsLoading = (state: StateShape): boolean =>
  state.isLoading.isStreamGroupsLoading

export const isAccountGroupsLoading = (state: StateShape): boolean =>
  state.isLoading.isAccountGroupsLoading

export const isTeamLoading = (state: StateShape): boolean =>
  state.isLoading.isTeamLoading

export const isTeamsByIdLoading = (state: StateShape): boolean =>
  state.isLoading.isTeamsByIdLoading

export const isTimelineLoading = (state: StateShape): boolean =>
  state.isLoading.isTimelineLoading

export const isTournamentLoading = (state: StateShape): boolean =>
  state.isLoading.isTournamentLoading

export const isTournamentCachedLoading = (state: StateShape): boolean =>
  state.isLoading.isTournamentCachedLoading

export const isTournamentsByIdLoading = (state: StateShape): boolean =>
  state.isLoading.isTournamentsByIdLoading

export const isTournamentsSummaryLoading = (state: StateShape): boolean =>
  state.isLoading.isTournamentsSummaryLoading

export const isOrganizationLoading = (state: StateShape): boolean =>
  state.isLoading.isOrganizationLoading

export const isCreateTournamentLoading = (state: StateShape): boolean =>
  state.isLoading.isCreateTournamentLoading

export const isPublishTournamentLoading = (state: StateShape): boolean =>
  state.isLoading.isPublishTournamentLoading

export const isUpdateTournamentLoading = (state: StateShape): boolean =>
  state.isLoading.isUpdateTournamentLoading

export const isSupportedRegionsLoading = (state: StateShape): boolean =>
  state.isLoading.isSupportedRegionsLoading

export const isSupportedLocalesLoading = (state: StateShape): boolean =>
  state.isLoading.isSupportedLocalesLoading

export const isCreateSeasonLoading = (state: StateShape): boolean =>
  state.isLoading.isCreateSeasonLoading

export const isCreateAccountsLoading = (state: StateShape): boolean =>
  state.isLoading.isCreateAccountsLoading

export const isSupportedMediaLocalesLoading = (state: StateShape): boolean =>
  state.isLoading.isSupportedMediaLocalesLoading

export const isMatchLoading = (state: StateShape): boolean =>
  state.isLoading.isMatchLoading

export const isGameLoading = (state: StateShape): boolean =>
  state.isLoading.isGameLoading

export const isVodLoading = (state: StateShape): boolean =>
  state.isLoading.isVodLoading

export const isAvailableVodsForGamesLoading = (state: StateShape): boolean =>
  state.isLoading.isAvailableVodsForGamesLoading

export const isScheduleForTournamentLoading = (state: StateShape): boolean =>
  state.isLoading.isScheduleForTournamentLoading

export const isUnscheduledForTournamentLoading = (state: StateShape): boolean =>
  state.isLoading.isUnscheduledLoading

export const isVodsDetailsLoading = (state: StateShape): boolean =>
  state.isLoading.isVodsDetailsLoading

export const isAccountOperationsForIdLoading = (state: StateShape): boolean =>
  state.isLoading.isAccountOperationsLoading

export const isMatchesLoading = (state: StateShape): boolean =>
  state.isLoading.isMatchesLoading
// #endregion

// #endregion
