import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import {
  Config,
  Logger,
  debounce,
  getSessionStorageData,
  setSessionStorageData
} from './commons'
// TODO: reset MUST be before index import to avoid overridden styles
import 'react-tooltip/dist/react-tooltip.css'
import './commons/Sass/reset.scss'
import { FeatureToggle, GridOverlay, Nav, Redirect } from './components'
import AccessError from './components/AccessDenied/AccessError'
import AuthComponent from './components/AuthComponent/AuthComponent'
import AuthNavigator from './components/AuthNavigator/AuthNavigator'
import { ConfigPanel } from './components/ConfigPanel/ConfigPanel'
import Loading from './components/Loading/Loading'
import LowerEnvironmentBorder from './components/LowerEnvironmentIndicator/LowerEnvironmentIndicator'
import Invitation from './containers/Invitation/Invitation'
import './index.scss'
import { AppWindow } from './index.type'
import UserManagementConfig from './services/UserManagement/UserManagementConfig'
import { loadState, saveState } from './store/localStorage'
import configureStore from './store/store'
import Annoucement, {
  AnnoucementType
} from './components/Annoucement/Annoucement'
import Datadog from './services/Datadog/Datadog'

const Accounts = React.lazy(() => import('./containers/Accounts/Accounts'))
const Leagues = React.lazy(() => import('./containers/Leagues/Leagues'))
const Pickem = React.lazy(() => import('./containers/Pickem/Pickem'))
const Players = React.lazy(() => import('./containers/Players/Players'))
const Teams = React.lazy(() => import('./containers/Teams/Teams'))
const Timeline = React.lazy(() => import('./containers/Timeline/Timeline'))
const Tournament = React.lazy(
  () => import('./containers/Tournament/Tournament')
)
const Streams = React.lazy(() => import('./containers/Streams/Streams'))
const Banners = React.lazy(() => import('./containers/Banners/Banners'))
const Drops = React.lazy(() => import('./containers/Drops/Drops'))
const Organizations = React.lazy(
  () => import('./containers/Organizations/Organizations')
)
const Season = React.lazy(() => import('./containers/Season/Season'))
const UserManagement = React.lazy(
  () => import('./containers/UserManagement/UserManagement')
)
const Shotcall = React.lazy(() => import('./containers/Shotcall/Shotcall'))

const LeaguesWithAuth = AuthComponent(Leagues)
const PickemWithAuth = AuthComponent(Pickem)
const PlayersWithAuth = AuthComponent(Players)
const TeamsWithAuth = AuthComponent(Teams)
const TimelineWithAuth = AuthComponent(Timeline)
const TournamentWithAuth = AuthComponent(Tournament)
const StreamsWithAuth = AuthComponent(Streams)
const BannersWithAuth = AuthComponent(Banners)
const DropsWithAuth = AuthComponent(Drops)
const OrganizationsWithAuth = AuthComponent(Organizations)
const SeasonWithAuth = AuthComponent(Season)
const UserManagementWithAuth = AuthComponent(UserManagement)
const AccountWithAuth = AuthComponent(Accounts)
const ShotcallWithAuth = AuthComponent(Shotcall)

const appWindow = window as AppWindow
const version = appWindow.APP_VERSION
const defaultHomePage = '/tournament/list/current'
const log = new Logger('App')

const persistedState = loadState(version)
const store = configureStore(persistedState)

store.subscribe(
  debounce(
    () =>
      saveState({
        metadata: store.getState().metadata,
        wip: store.getState().wip,
        userConfig: store.getState().userConfig
      }),
    1000,
    false
  )
)

Datadog.init()

function App () {
  const getHomePageRedirect = (): string => {
    const currentPageRedirect = getSessionStorageData('current_page_redirect')
    if (
      currentPageRedirect
      && !currentPageRedirect.includes('invite_code')
      && currentPageRedirect !== '/'
    ) {
      return currentPageRedirect
    }
    else {
      return defaultHomePage
    }
  }

  const INVITE_CODE = 'invite_code'
  const extractAndStoreInviteCode = (param: any): void => {
    const qs = new URLSearchParams(param)
    const inviteCode = qs.get(INVITE_CODE)
    if (inviteCode) {
      setSessionStorageData(INVITE_CODE, inviteCode)
    }
  }

  // Permit.IO invite code handling
  const hasInviteCode = (): boolean => {
    const currentPageRedirect = getSessionStorageData('current_page_redirect')
    if (currentPageRedirect && currentPageRedirect.includes(INVITE_CODE)) {
      extractAndStoreInviteCode(currentPageRedirect)
    }
    else {
      extractAndStoreInviteCode(window.location.search)
    }
    log.debug('(@) invite code extracted', getSessionStorageData(INVITE_CODE))
    return getSessionStorageData(INVITE_CODE) != null
  }

  // Check if hostname is lolesports
  const isLolesports = (): boolean =>
    window.location.hostname.includes('lolesports')

  // Permit.IO metadata extraction. Refer to README.
  const { envId, elementAccess } = UserManagementConfig.getConfigs()
  const userPageAccess = elementAccess?.split(',')
  log.debug('(@) Element Access', userPageAccess)
  log.info('(@) Env')
  return (
    <Provider store={ store }>
      { !Config.isProdEnv() && <GridOverlay/> }
      { !Config.isProdEnv() && <LowerEnvironmentBorder/> }
      { isLolesports() && (
        <Annoucement type={ AnnoucementType.Warning }>
          <span>
            emp.lolesports.com is now deprecated. Please use{ ' ' }
            <a href="https://emp.riotesports.com">emp.riotesports.com</a>{ ' ' }
            instead.
          </span>
        </Annoucement>
      ) }
      <Suspense fallback={ <Loading/> }>
        <Router>
          <AuthNavigator log={ log }>
            <Nav/>
            <Routes>
              <Route
                path="/"
                element={
                  hasInviteCode() ? (
                    <Invitation
                      envId={ envId }
                      inviteCode={
                        getSessionStorageData('invite_code') as string
                      }
                    />
                  ) 
                    : <Redirect to={ getHomePageRedirect() }/>
                  
                }
              />
              <Route
                path="/accounts/*"
                element={
                  <FeatureToggle feature="accounts">
                    <AccountWithAuth allowed={ ['account', 'Account'] }/>
                  </FeatureToggle>
                }
              />
              <Route
                path="/leagues/*"
                element={ <LeaguesWithAuth allowed={ ['league', 'League'] }/> }
              />
              <Route
                path="/pickem/*"
                element={
                  <PickemWithAuth
                    allowed={ ['pickem', 'Pickem', 'Pickem_Tournament'] }
                  />
                }
              />
              <Route
                path="/players/*"
                element={ <PlayersWithAuth allowed={ ['player', 'Player'] }/> }
              />
              <Route
                path="/teams/*"
                element={ <TeamsWithAuth allowed={ ['team', 'Team'] }/> }
              />
              <Route
                path="/timeline/*"
                element={
                  <TimelineWithAuth
                    allowed={ [
                      'stream',
                      'Stream',
                      'Stream_Timeline',
                      'Livestats'
                    ] }
                  />
                }
              />
              <Route
                path="/tournament/*"
                element={
                  <TournamentWithAuth allowed={ ['tournament', 'Tournament'] }/>
                }
              />
              <Route
                path="/stream/*"
                element={
                  <StreamsWithAuth
                    allowed={ [
                      'stream',
                      'Stream',
                      'Stream_Timeline',
                      'Livestats'
                    ] }
                  />
                }
              />
              <Route
                path="/banners/*"
                element={ <BannersWithAuth allowed={ ['banners', 'Banners'] }/> }
              />
              <Route
                path="/drops/*"
                element={
                  <DropsWithAuth
                    allowed={ [
                      'drop',
                      'Drop',
                      'Drops_Inventory_Item',
                      'Drops_Localization_Group',
                      'Drop_Trigger',
                      'Drops_Group'
                    ] }
                  />
                }
              />
              <Route
                path="/organizations/*"
                element={
                  <OrganizationsWithAuth
                    allowed={ ['organization', 'Organization'] }
                  />
                }
              />
              <Route
                path="/season/*"
                element={ <SeasonWithAuth allowed={ ['season', 'Season'] }/> }
              />
              <Route
                path="/shotcall/*"
                element={
                  <ShotcallWithAuth
                    allowed={ ['Shotcall_Question', 'Shotcall_Answer'] }
                  />
                }
              />
              <Route
                path="/usermanagement/*"
                element={
                  <FeatureToggle feature="rbac">
                    <UserManagementWithAuth requiredRoles={ userPageAccess }/>
                  </FeatureToggle>
                }
              />
            </Routes>
          </AuthNavigator>
        </Router>
      </Suspense>
      { !Config.isProdEnv() && <ConfigPanel/> }
      <AccessError/>
    </Provider>
  )
}

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)
  root.render(<App/>)
}
else {
  log.error('Element with id=root not found.')
}
