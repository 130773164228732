import { combineReducers, Reducer } from 'redux'
import { keyById } from '../../commons'
import { AnyPayloadAction, PayloadAction } from '../../store/Store.type'
import { createSlice } from '../../store/actions/utils'
import {
  createAnswers,
  createQuestion,
  editAnswers,
  editQuestions,
  getAnswer,
  getAnswers,
  getPrompts,
  getContestPrompts,
  getQuestion,
  getQuestions,
  createPrompts,
  triggerPrompts,
  resolvePrompts
} from './ShotcallApi.actions'
import {
  ShotcallAnswerDTO,
  ShotcallPromptDTO,
  ShotcallQuestionDTO
} from './ShotcallApi.type'

type StateShape = ReturnType<typeof combinedReducer>

const keyByIdReducer = <T extends { id?: string }>(
  state: Record<string, T>,
  { payload }: PayloadAction<T[]>
): Record<string, T> =>
    payload.reduce(
      (acc, curr) => {
        if (!curr.id) {
          return acc
        }

        acc[curr.id] = curr
        return acc
      },
      { ...state }
    )

const question = createSlice({
  name: 'question',
  initialState: {} as Record<string, ShotcallQuestionDTO>,
  extraReducers: {
    [getQuestion.success.type]: (
      state,
      action: PayloadAction<ShotcallQuestionDTO>
    ) =>
      keyByIdReducer(state ?? {}, {
        payload: [action.payload],
        type: action.type
      }),
    [getQuestions.success.type]: (
      _,
      action: PayloadAction<ShotcallQuestionDTO[]>
    ) => keyById(action.payload),
    [createQuestion.success.type]: (
      state,
      action: PayloadAction<ShotcallQuestionDTO>
    ) =>
      keyByIdReducer(state ?? {}, {
        payload: [action.payload],
        type: action.type
      }),
    [editQuestions.success.type]: keyByIdReducer
  } as Record<
    string,
    Reducer<Record<string, ShotcallQuestionDTO>, AnyPayloadAction>
  >
})

const answer = createSlice({
  name: 'answer',
  initialState: {} as Record<string, ShotcallAnswerDTO>,
  extraReducers: {
    [getAnswer.success.type]: (
      state,
      action: PayloadAction<ShotcallAnswerDTO>
    ) =>
      keyByIdReducer(state ?? {}, {
        payload: [action.payload],
        type: action.type
      }),
    [getAnswers.success.type]: (
      _,
      action: PayloadAction<ShotcallAnswerDTO[]>
    ) => keyById(action.payload),
    [createAnswers.success.type]: keyByIdReducer,
    [editAnswers.success.type]: keyByIdReducer
  } as Record<
    string,
    Reducer<Record<string, ShotcallAnswerDTO>, AnyPayloadAction>
  >
})

const prompt = createSlice({
  name: 'prompt',
  initialState: {} as Record<string, ShotcallPromptDTO>,
  extraReducers: {
    [getPrompts.success.type]: (
      _,
      action: PayloadAction<ShotcallPromptDTO[]>
    ) => keyById(action.payload),
    [getContestPrompts.success.type]: (
      _,
      action: PayloadAction<ShotcallPromptDTO[]>
    ) => keyById(action.payload),
    [createPrompts.success.type]: keyByIdReducer,
    [triggerPrompts.success.type]: keyByIdReducer,
    [resolvePrompts.success.type]: keyByIdReducer
  } as Record<
    string,
    Reducer<Record<string, ShotcallPromptDTO>, AnyPayloadAction>
  >
})

const combinedReducer = combineReducers({
  question: question.reducer,
  answer: answer.reducer,
  prompt: prompt.reducer
})

export const getShotcallQuestions = (
  state: StateShape
): Record<string, ShotcallQuestionDTO> => state.question

export const getShotcallAnswers = (
  state: StateShape
): Record<string, ShotcallAnswerDTO> => state.answer

export const getShotcallPrompts = (
  state: StateShape
): Record<string, ShotcallPromptDTO> => state.prompt

export default combinedReducer
