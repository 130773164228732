import { Config, fetch, urlBuilder } from '../../commons'
import EmpApiConfig, { ServiceCategory } from '../EMP/EmpApiConfig'
import {
  NewShotcallAnswerDTO,
  NewShotcallPromptDTO,
  NewShotcallQuestionDTO,
  ShotcallAnswerDTO,
  ShotcallPromptDTO,
  ShotcallPromptResolution,
  ShotcallPromptTrigger,
  ShotcallQuestionDTO,
  UpdateShotcallAnswerDTO,
  UpdateShotcallQuestionDTO
} from './ShotcallApi.type'

export const ShotcallApiEnvSessionStorageKey = 'shotcallsApiEnv'

const apiBaseUrl = EmpApiConfig.getApiBaseUrl(
  ServiceCategory.Shotcall,
  Config.getEnv(ShotcallApiEnvSessionStorageKey)
)

const shotcallBaseUrl = Config.getKeyByEnv({
  local: apiBaseUrl,
  dev: apiBaseUrl,
  test: apiBaseUrl,
  stage: apiBaseUrl,
  prod: apiBaseUrl
})

const shotcallQuestionsBaseUrl = `${shotcallBaseUrl}/shotcall/v1/questions`
const shotcallAnswersBaseUrl = `${shotcallBaseUrl}/shotcall/v1/answers`
const shotcallPromptsBaseUrl = `${shotcallBaseUrl}/shotcall/v1/prompts`

const questionsUrl = urlBuilder({
  local: shotcallQuestionsBaseUrl,
  dev: shotcallQuestionsBaseUrl,
  test: shotcallQuestionsBaseUrl,
  prod: shotcallQuestionsBaseUrl
})

const answersUrl = urlBuilder({
  local: shotcallAnswersBaseUrl,
  dev: shotcallAnswersBaseUrl,
  test: shotcallAnswersBaseUrl,
  prod: shotcallAnswersBaseUrl
})

const promptsUrl = urlBuilder({
  local: shotcallPromptsBaseUrl,
  dev: shotcallPromptsBaseUrl,
  test: shotcallPromptsBaseUrl,
  prod: shotcallPromptsBaseUrl
})

class ShotcallApi {
  static getOptions (method = 'GET'): RequestInit {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

    return {
      method: method,
      headers: headers
    }
  }

  async getQuestions (): Promise<ShotcallQuestionDTO[]> {
    return await fetch(questionsUrl(''), ShotcallApi.getOptions())
  }

  async getQuestion (questionId: string): Promise<ShotcallQuestionDTO> {
    return await fetch(questionsUrl(`/${questionId}`), ShotcallApi.getOptions())
  }

  async editQuestions (
    questions: UpdateShotcallQuestionDTO[]
  ): Promise<ShotcallQuestionDTO[]> {
    return await fetch(questionsUrl(''), {
      ...ShotcallApi.getOptions('PUT'),
      body: JSON.stringify(questions)
    })
  }

  async createQuestion (
    question: NewShotcallQuestionDTO
  ): Promise<ShotcallQuestionDTO> {
    return await fetch(questionsUrl(''), {
      ...ShotcallApi.getOptions('POST'),
      body: JSON.stringify(question)
    })
  }

  async getAnswers (): Promise<ShotcallAnswerDTO[]> {
    return await fetch(answersUrl(''), ShotcallApi.getOptions())
  }

  async getAnswer (answerId: string): Promise<ShotcallAnswerDTO> {
    return await fetch(answersUrl(`/${answerId}`), ShotcallApi.getOptions())
  }

  async editAnswers (
    answers: UpdateShotcallAnswerDTO[]
  ): Promise<ShotcallAnswerDTO[]> {
    return await fetch(answersUrl(''), {
      ...ShotcallApi.getOptions('PUT'),
      body: JSON.stringify(answers)
    })
  }

  async createAnswers (
    answers: NewShotcallAnswerDTO[]
  ): Promise<ShotcallAnswerDTO[]> {
    return await fetch(answersUrl(''), {
      ...ShotcallApi.getOptions('POST'),
      body: JSON.stringify(answers)
    })
  }

  async getPrompts (): Promise<ShotcallPromptDTO[]> {
    return await fetch(promptsUrl(''), ShotcallApi.getOptions())
  }

  async getContestPrompts (contestId: string): Promise<ShotcallPromptDTO[]> {
    return await fetch(promptsUrl(`/${contestId}`), ShotcallApi.getOptions())
  }

  async createPrompts (
    prompts: NewShotcallPromptDTO[]
  ): Promise<ShotcallPromptDTO[]> {
    return await fetch(promptsUrl(''), {
      ...ShotcallApi.getOptions('POST'),
      body: JSON.stringify(prompts)
    })
  }

  async triggerPrompts (
    triggers: ShotcallPromptTrigger[]
  ): Promise<ShotcallPromptDTO[]> {
    return await fetch(promptsUrl('/trigger'), {
      ...ShotcallApi.getOptions('POST'),
      body: JSON.stringify(triggers)
    })
  }

  async resolvePrompts (
    resolutions: ShotcallPromptResolution[]
  ): Promise<ShotcallPromptDTO[]> {
    return await fetch(promptsUrl('/resolve'), {
      ...ShotcallApi.getOptions('POST'),
      body: JSON.stringify(resolutions)
    })
  }
}

export default new ShotcallApi()
