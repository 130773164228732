import ShotcallApi from './ShotcallApi'
import { createThunk } from '../../store/actions/utils'

export const getQuestions = createThunk(
  'shotcallApi/getQuestions',
  ShotcallApi.getQuestions
)

export const getQuestion = createThunk(
  'shotcallApi/getQuestion',
  ShotcallApi.getQuestion
)

export const editQuestions = createThunk(
  'shotcallApi/editQuestions',
  ShotcallApi.editQuestions
)

export const createQuestion = createThunk(
  'shotcallApi/createQuestion',
  ShotcallApi.createQuestion
)

export const getAnswers = createThunk(
  'shotcallApi/getAnswers',
  ShotcallApi.getAnswers
)

export const getAnswer = createThunk(
  'shotcallApi/getAnswer',
  ShotcallApi.getAnswer
)

export const editAnswers = createThunk(
  'shotcallApi/editAnswers',
  ShotcallApi.editAnswers
)

export const createAnswers = createThunk(
  'shotcallApi/createAnswers',
  ShotcallApi.createAnswers
)

export const getPrompts = createThunk(
  'shotcallApi/getPrompts',
  ShotcallApi.getPrompts
)

export const getContestPrompts = createThunk(
  'shotcallApi/getContestPrompts',
  ShotcallApi.getContestPrompts
)

export const createPrompts = createThunk(
  'shotcallApi/createPrompts',
  ShotcallApi.createPrompts
)

export const triggerPrompts = createThunk(
  'shotcallApi/triggerPrompts',
  ShotcallApi.triggerPrompts
)

export const resolvePrompts = createThunk(
  'shotcallApi/resolvePrompts',
  ShotcallApi.resolvePrompts
)
