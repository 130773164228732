import { DropRequestDTO } from '@riotgames/api-types/drops/DropsV2.type'
import { Tournament } from '@riotgames/api-types/elds/Tournaments.type'
import { combineReducers, compose } from 'redux'
import { BannerConfigurationDTO } from '../../../containers/Banners/Banners.type'
import tournaments, * as fromTournaments from '../../../containers/Tournament/Tournament.slice'
import { NormalizedTournament } from '../../../containers/Tournament/Tournament.type'
import banners, * as fromBanners from './Banners/Banners'
import drops, * as fromDrops from './Drops/Drops'
import organizations, * as fromOrganizations from './Organizations/Organization/Organization'
import season, * as fromSeason from '../../../containers/Season/Season.slice'
import { Season } from '@riotgames/api-types/elds/Seasons.type'
import accounts, * as fromAccounts from '../../../containers/Accounts/Accounts.slice'
import players, * as fromPlayers from '../../../containers/Players/Players.slice'
import { TournamentRealmAccount } from '@riotgames/api-types/elds/Accounts.type'
import statSync, * as fromStatSync from '../../../containers/Tournament/Management/StatSync/Modal/StatSyncModal.slice'
import tournamentAdmin, * as fromTournamentAdmin from './TournamentAdmin/TournamentAdmin'

const combinedReducer = combineReducers({
  accounts,
  players,
  drops,
  banners,
  organizations,
  tournaments,
  season,
  statSync,
  tournamentAdmin
})

export default combinedReducer

type StateShape = ReturnType<typeof combinedReducer>

const getAccounts = (state: StateShape) => state.accounts
const getPlayers = (state: StateShape) => state.players
const getOrganizations = (state: StateShape) => state.organizations
const getTournaments = (state: StateShape) => state.tournaments
const getSeason = (state: StateShape) => state.season
const getStatSync = (state: StateShape) => state.statSync
const getTournamentAdmin = (state: StateShape) => state.tournamentAdmin

// #region Accounts

export const getWIPEditAccounts = (state: StateShape) =>
  fromAccounts.getWIPEditAccounts(getAccounts(state))

export const getWIPAccounts = (state: StateShape) =>
  fromAccounts.getAccounts(getAccounts(state))

export const isWIPAccountsDirty = (state: StateShape) =>
  fromAccounts.isWIPAccountsDirty(getAccounts(state))

export const isWIPAccountsInvalid = (state: StateShape) =>
  fromAccounts.isWIPAccountsInvalid(getAccounts(state))

export const getSelectedSportForNewAccounts = (state: StateShape) =>
  fromAccounts.getSelectedSport(getAccounts(state))

export const getExpirationDateForNewAccounts = (state: StateShape) =>
  fromAccounts.getExpirationDate(getAccounts(state))

export const getWIPAccountById = (state: StateShape, id: string) =>
  fromAccounts.getAccountById(getAccounts(state), id)

export const getSelectedGroupForNewAccounts = (state: StateShape) =>
  fromAccounts.getAccountGroupId(getAccounts(state))

export const isEditAccountDirty = (
  state: StateShape,
  accountId: string,
  account: TournamentRealmAccount
) => fromAccounts.isEditAccountDirty(getAccounts(state), accountId, account)

export const isEditAccountDisplayLoginDirty = (
  state: StateShape,
  accountId: string,
  account: TournamentRealmAccount
) =>
  fromAccounts.isEditAccountDisplayLoginDirty(
    getAccounts(state),
    accountId,
    account
  )

export const getWIPExportAccountIds = (state: StateShape) =>
  fromAccounts.getExportAccountIds(getAccounts(state))
export const getWIPExportAccountsHashMap = (state: StateShape) =>
  fromAccounts.getExportAccountHashmap(getAccounts(state))

// #endregion

export const getWIPRemoveAccountsHashMap = (state: StateShape) =>
  fromAccounts.getRemoveAccountHashmap(getAccounts(state))

export const getWIPAccountsCSV = (state: StateShape) =>
  fromAccounts.getCSVAccounts(getAccounts(state))

export const getWIPAccountsDuplicate = (state: StateShape) =>
  fromAccounts.getDuplicateAccountsArr(getAccounts(state))

export const getWIPAccountsExisting = (state: StateShape) =>
  fromAccounts.getExistingAccountsArr(getAccounts(state))

export const getWIPAccountCollisionDetails = (state: StateShape) =>
  fromAccounts.getAccountCollisionDetails(getAccounts(state))

export const getWIPAccountsInvalid = (state: StateShape) =>
  fromAccounts.getInvalidAccountsArr(getAccounts(state))

export const getWIPAccountsTab = (state: StateShape) =>
  fromAccounts.getWIPAccountsTab(getAccounts(state))

export const getWIPPlayers = (state: StateShape) =>
  fromPlayers.getPlayers(getPlayers(state))

export const getWIPPlayersCSV = (state: StateShape) =>
  fromPlayers.getCSVPlayers(getPlayers(state))

export const getWIPPlayersCSVInvalid = (state: StateShape) =>
  fromPlayers.getCSVInvalidPlayers(getPlayers(state))

export const isWIPPlayersDirty = (state: StateShape) =>
  fromPlayers.isWIPPlayersDirty(getPlayers(state))

export const getWIPPlayersDuplicate = (state: StateShape) =>
  fromPlayers.getDuplicatePlayersArr(getPlayers(state))

export const getWIPPlayersExisting = (state: StateShape) =>
  fromPlayers.getExistingPlayersArr(getPlayers(state))

export const getWIPPlayersInvalid = (state: StateShape) =>
  fromPlayers.getInvalidPlayersArr(getPlayers(state))

export const getWIPPlayersTab = (state: StateShape) =>
  fromPlayers.getWIPPlayersTab(getPlayers(state))

// #region Stat Sync
export const getEditOffsetById = (
  state: StateShape,
  gameId: string,
  providerId: string
) => fromStatSync.getEditOffsetById(getStatSync(state), gameId, providerId)

export const isEditOffsetDirty = (state: StateShape, id: string) =>
  fromStatSync.isEditOffsetDirty(getStatSync(state), id, {})

export const getEditProviderIdById = (state: StateShape, id: string) =>
  fromStatSync.getEditProviderIdById(getStatSync(state), id)

export const getEditGameIdByMatchId = (state: StateShape, matchId: string) =>
  fromStatSync.getEditGameIdByMatchId(getStatSync(state), matchId)

export const getEditGameTimeById = (
  state: StateShape,
  gameId: string,
  providerId: string
) => fromStatSync.getEditGameTimeById(getStatSync(state), gameId, providerId)

export const getEditLivestatsStatusById = (
  state: StateShape,
  gameId: string,
  providerId: string
) =>
  fromStatSync.getEditLivestatsStatusById(
    getStatSync(state),
    gameId,
    providerId
  )
// #endregion

// #region Drops
export const getWIPInventoryItem = (state: StateShape) =>
  fromDrops.getWIPInventoryItem(state.drops)

export const getWIPPromoCodeContext = (state: StateShape) =>
  fromDrops.getWIPPromoCodeContext(state.drops)

export const isInventoryItemDirty = (state: StateShape) =>
  fromDrops.isInventoryItemDirty(state.drops)

export const isInventoryItemChampionSkin = (state: StateShape) =>
  fromDrops.isInventoryItemChampionSkin(state.drops)

export const getWIPRarity = (state: StateShape) =>
  fromDrops.getWIPRarity(state.drops)

export const isRarityDirty = (state: StateShape) =>
  fromDrops.isRarityDirty(state.drops)

export const getWIPTrigger = (state: StateShape) =>
  fromDrops.getWIPTrigger(state.drops)

export const getWIPGroup = (state: StateShape) =>
  fromDrops.getWIPGroup(state.drops)

export const getWIPFirstDrop = (state: StateShape) =>
  fromDrops.getWIPFirstDrop(state.drops)
export const getWIPFirstDropField = <K extends keyof DropRequestDTO>(
  state: StateShape,
  key: K
) => fromDrops.getWIPFirstDropField(state.drops, key)
export const getWIPDropByID = (state: StateShape, id: string | null) =>
  fromDrops.getWIPDropByID(state.drops, id)
export const getWIPGroupGeolocations = (state: StateShape) =>
  fromDrops.getWIPGroupGeolocations(state.drops)

export const getWIPGroupDrops = (state: StateShape) =>
  fromDrops.getWIPGroupDrops(state.drops)

export const getWIPRarityMeta = (state: StateShape) =>
  fromDrops.getWIPRarityMeta(state.drops)
export const getWIPMeta = (state: StateShape) =>
  fromDrops.getWIPMeta(state.drops)
export const getWIPIndividualTriggerMeta = (state: StateShape) =>
  fromDrops.getWIPIndividualTriggerMeta(state.drops)
export const getWIPTriggerMeta = (state: StateShape) =>
  fromDrops.getWIPTriggerMeta(state.drops)

export const getWIPGroupTitleLocalizations = (state: StateShape) =>
  fromDrops.getWIPGroupTitleLocalizations(state.drops)

export const getWIPGroupDescriptionLocalizations = (state: StateShape) =>
  fromDrops.getWIPGroupDescriptionLocalizations(state.drops)

export const getWIPInventoryTitleLocalizations = (state: StateShape) =>
  fromDrops.getWIPInventoryTitleLocalizations(state.drops)

export const getWIPInventoryDescriptionLocalizations = (state: StateShape) =>
  fromDrops.getWIPInventoryDescriptionLocalizations(state.drops)

export const getFulfillmentStatusDefaultDescriptions = (state: StateShape) =>
  fromDrops.getFulfillmentStatusDefaultDescriptions(state.drops)

export const getFetchingDefaultDescription = (state: StateShape) =>
  fromDrops.getFetchingDefaultDescription(state.drops)

export const isGroupDirty = (state: StateShape) =>
  fromDrops.isGroupDirty(state.drops)
export const isDropLocalizationDirty = (state: StateShape) =>
  fromDrops.isDropLocalizationDirty(state.drops)

export const isMetaDirty = (state: StateShape) =>
  fromDrops.isMetaDirty(state.drops)

export const isImageUploading = (state: StateShape) =>
  fromDrops.isImageUploading(state.drops)

export const isDropCapValid = (state: StateShape) =>
  fromDrops.isDropCapValid(state.drops)

// #endregion

// #region TournamentAdmin

export const isTournamentAdminDirty = (state: StateShape) =>
  fromTournamentAdmin.isTournamentAdminDirty(state.tournamentAdmin)

export const getWIPTournamentAdmin = (state: StateShape) =>
  fromTournamentAdmin.getWIPTournamentAdmin(state.tournamentAdmin)

// #endregion

// #region Banners

export const getWIPBannerConfiguration = (
  state: StateShape
): BannerConfigurationDTO =>
  fromBanners.getWIPBannerConfiguration(state.banners)

export const isBannerConfigurationDirty = (state: StateShape) =>
  fromBanners.isBannerConfigurationDirty(state.banners)

export const isBannerConfigurationUploading = (state: StateShape) =>
  fromBanners.isBannerConfigurationUploading(state.banners)

export const getBannerGroups = (state: StateShape) =>
  fromBanners.getBannerGroups(state.banners)

export const wipConfigHasAssignedAssets = (state: StateShape) =>
  fromBanners.wipConfigHasAssignedAssets(state.banners)

// #endregion

// #region Organizations

export const getWIPOrganization = compose(
  fromOrganizations.getWIPOrganization,
  getOrganizations
)

export const isOrganizationImageUploading = (state: StateShape) =>
  fromOrganizations.isOrganizationImageUploading(state.organizations)

export const isOrganizationDirty = (state: StateShape) =>
  fromOrganizations.isOrganizationDirty(state.organizations)

// #endregion

// #region Tournaments
export const getFieldForWIPTournament
  = <K extends keyof NormalizedTournament>(key: string) =>
    (state: StateShape, id: string | null): NormalizedTournament[K] =>
    fromTournaments.getFieldForWIPTournament(key)(
      state.tournaments,
      id
    ) as NormalizedTournament[K]

export const getEdgeMapForSection = (
  state: StateShape,
  tournamentId: string,
  sectionId: string
) =>
  fromTournaments.getEdgeMapForSection(
    state.tournaments,
    tournamentId,
    sectionId
  )

export const getWIPTournamentById = (state: StateShape, id: string) =>
  fromTournaments.getWIPTournamentById(getTournaments(state), id)

export const getStageIdsForTournament = (state: StateShape, id: string) =>
  fromTournaments.getStageIdsForTournament(getTournaments(state), id)

export const getStageForTournament = (
  state: StateShape,
  tournamentId: string,
  stageId: string
) =>
  fromTournaments.getStageForTournament(
    getTournaments(state),
    tournamentId,
    stageId
  )

export const getSectionForTournament = (
  state: StateShape,
  tournamentId: string,
  sectionId: string
) =>
  fromTournaments.getSectionForTournament(
    getTournaments(state),
    tournamentId,
    sectionId
  )

export const getStandingForWIPTournament = (
  state: StateShape,
  tournamentId: string
) =>
  fromTournaments.getStandingForWIPTournament(
    getTournaments(state),
    tournamentId
  )

export const getColumnForWIPSection = (
  state: StateShape,
  tournamentId: string,
  sectionId: string,
  columnId: number
) =>
  fromTournaments.getColumnForWIPSection(
    getTournaments(state),
    tournamentId,
    sectionId,
    columnId
  )

export const getMatchForWIPTournament = (
  state: StateShape,
  tournamentId: string,
  matchId: string
) =>
  fromTournaments.getMatchForWIPTournament(
    getTournaments(state),
    tournamentId,
    matchId
  )

export const getMatchCellForWIPSection = (
  state: StateShape,
  tournamentId: string,
  sectionId: string,
  columnId: number,
  cellId: number
) =>
  fromTournaments.getMatchCellForWIPSection(
    getTournaments(state),
    tournamentId,
    sectionId,
    columnId,
    cellId
  )

export const getDecisionPointsForWIPSection = (
  state: StateShape,
  tournamentId: string,
  sectionId: string
) =>
  fromTournaments.getDecisionPointsForWIPSection(
    getTournaments(state),
    tournamentId,
    sectionId
  )

export const canDeleteColumnFromSection = (
  state: StateShape,
  tournamentId: string,
  sectionId: string,
  columnId: number
) =>
  fromTournaments.canDeleteColumnFromSection(
    getTournaments(state),
    tournamentId,
    sectionId,
    columnId
  )

export const getCircuitPointsForWIPTournament = (
  state: StateShape,
  tournamentId: string
) =>
  fromTournaments.getCircuitPointsForWIPTournament(
    getTournaments(state),
    tournamentId
  )

export const getLabelForTournamentEdge = (
  state: StateShape,
  tournamentId: string,
  targetNodeId: string | null,
  targetSlot: number,
  abbrev?: boolean
) =>
  fromTournaments.getLabelForTournamentEdge(
    getTournaments(state),
    tournamentId,
    targetNodeId,
    targetSlot,
    abbrev
  )

export const getLabelForStageEdge = (
  state: StateShape,
  tournamentId: string,
  stageId: string,
  targetNodeId: string,
  targetSlot: number
) =>
  fromTournaments.getLabelForStageEdge(
    getTournaments(state),
    tournamentId,
    stageId,
    targetNodeId,
    targetSlot
  )

export const getLabelForSectionEdge = (
  state: StateShape,
  tournamentId: string,
  sectionId: string,
  targetNodeId: string,
  targetSlot: number
) =>
  fromTournaments.getLabelForSectionEdge(
    getTournaments(state),
    tournamentId,
    sectionId,
    targetNodeId,
    targetSlot
  )

export const getOptionsForStandingSlot = (
  state: StateShape,
  tournamentId: string,
  sectionId: string,
  slot: number
) =>
  fromTournaments.getOptionsForStandingSlot(
    getTournaments(state),
    tournamentId,
    sectionId,
    slot
  )

export const getOptionsForMatchSlot = (
  state: StateShape,
  tournamentId: string,
  matchId: string,
  slot: number
) =>
  fromTournaments.getOptionsForMatchSlot(
    getTournaments(state),
    tournamentId,
    matchId,
    slot
  )

export const getOptionsForDecisionPointSlot = (
  state: StateShape,
  tournamentId: string,
  decisionPointId: string,
  slot: number
) =>
  fromTournaments.getOptionsForDecisionPointSlot(
    getTournaments(state),
    tournamentId,
    decisionPointId,
    slot
  )

export const getWIPTournamentSlug = (state: StateShape, tournamentId: string) =>
  fromTournaments.getWIPTournamentSlug(getTournaments(state), tournamentId)

export const areLocalizationsInvalid = (
  state: StateShape,
  tournamentId: string
) =>
  fromTournaments.areLocalizationsInvalid(getTournaments(state), tournamentId)

export const isTournamentDirty = (
  state: StateShape,
  tournamentId: string,
  tournament: Tournament
) =>
  fromTournaments.isTournamentDirty(
    getTournaments(state),
    tournamentId,
    tournament
  )

export const areTournamentRequiredFieldsMissing = (
  state: StateShape,
  tournamentId: string
) =>
  fromTournaments.areTournamentRequiredFieldsMissing(
    getTournaments(state),
    tournamentId
  )

export const isTournamentFullyPublished = (
  state: StateShape,
  tournamentId: string
) =>
  fromTournaments.isTournamentFullyPublished(
    getTournaments(state),
    tournamentId
  )

export const getTournamentBuilderData = (state: StateShape) =>
  fromTournaments.getUIData(getTournaments(state))

export const getCopyTournamentData = (state: StateShape) =>
  fromTournaments.getCopyTournamentData(getTournaments(state))

export const getStageThatContainsSection = (
  state: StateShape,
  tournamentId: string,
  stageId: string
) =>
  fromTournaments.getStageThatContainsSection(
    getTournaments(state),
    tournamentId,
    stageId
  )

export const isWIPTournamentSummary = (state: StateShape, id: string) =>
  fromTournaments.isWIPTournamentSummary(getTournaments(state), id)

// #endregion

// #region Season

export const getWIPSeasonById = (state: StateShape, id: string) =>
  fromSeason.getWIPSeasonById(getSeason(state), id)

export const getSlugForSeason = (state: StateShape, id: string) =>
  fromSeason.getSlugForSeason(getSeason(state), id)

export const getSportForSeason = (state: StateShape, id: string) =>
  fromSeason.getSportForSeason(getSeason(state), id)

export const getStartTimeForSeason = (state: StateShape, id: string) =>
  fromSeason.getStartTimeForSeason(getSeason(state), id)

export const getEndTimeForSeason = (state: StateShape, id: string) =>
  fromSeason.getEndTimeForSeason(getSeason(state), id)

export const getWIPSplitByIndex = (
  state: StateShape,
  seasonId: string,
  index: number
) => fromSeason.getWIPSplitByIndex(getSeason(state), seasonId, index)

export const isSeasonDirty = (state: StateShape, id: string, season: Season) =>
  fromSeason.isSeasonDirty(getSeason(state), id, season)

export const getUIDataForSplits = (state: StateShape) =>
  fromSeason.getUIDataForSplits(getSeason(state))

// #endregion
