/* eslint-disable max-lines */
import {
  DecisionPointConfig,
  Edge,
  LineupEntry,
  MatchCell,
  MatchColumn,
  MatchView,
  NodeType
} from '@riotgames/api-types/elds/Tournaments.type'
import { entry } from '../../../../webpack.config'
import {
  createArray,
  createEdge,
  getLocalizedName,
  getSafe,
  to,
  toOrdinal
} from '../../../commons'
import { DropdownOption } from '../../../components/Dropdown/Dropdown.type'
import { NormalizedSection, ReducerShape } from '../Tournament.type'
import { HasLocalizedStringsWithSlug, HasSeedingAndStanding } from './Utils'

export type MappedEdge = Partial<Edge> & { humanReadableString?: string }

export type EdgeMap = Record<number, Record<string, MappedEdge>>

export type HasEdges = { edges: Edge[] }

export type TournamentSegment = HasEdges &
  HasSeedingAndStanding &
  HasLocalizedStringsWithSlug

// ELDS omits null fields from its responses, but expects them in its payloads.
// To work around this, we are forcing nodeIds to not be undefined when working
// with edges locally in EMP.  This ensures that we can rely on them to be fully
// formed at all times, without worrying about if we are dealing with an edge
// that came from ELDS or one we generated ourselves.
export const fixEdge = (edge: Edge) => createEdge(edge)

export const sameTargetNode = (
  edge: Partial<Edge>,
  otherEdge: Partial<Edge>
): boolean =>
  edge.targetSlot === otherEdge.targetSlot
  && edge.targetNodeId === otherEdge.targetNodeId

export const sameSourceNode = (
  edge: Partial<Edge>,
  otherEdge: Partial<Edge>
): boolean =>
  edge.sourceSlot === otherEdge.sourceSlot
  && edge.sourceNodeId === otherEdge.sourceNodeId

export const getEdgeTargeting = <T extends HasEdges>(
  entity: T,
  targetNodeId: string | null,
  targetSlot: number
): Edge =>
  entity.edges.find((edge) =>
    sameTargetNode(edge, { targetNodeId: targetNodeId as string, targetSlot })
  ) as Edge

export const edgeToHashString = (edge: Partial<Edge>): string =>
  `${edge.sourceNodeId}-${edge.sourceSlot}`

export const buildSourceEdgesFromSeeding = (
  segment: TournamentSegment
): MappedEdge[] =>
  segment.seeding.entries.map((entry) => ({
    sourceSlot: entry.index,
    sourceNode: null,
    sourceType: NodeType.Seeding,
    humanReadableString: `${getLocalizedName(segment.name)} - ${toOrdinal(
      entry.index
    )} Seed`
  }))

export const buildSourceEdgesFromDecisionPoints = (
  decisionPoints: DecisionPointConfig[]
): MappedEdge[] =>
  decisionPoints.flatMap((decisionPoint) =>
    decisionPoint.standing.entries.map((entry) => ({
      sourceSlot: entry.index,
      sourceNode: decisionPoint.structuralId,
      sourceType: NodeType.DecisionPoint,
      humanReadableString: entry.description
    }))
  )

const matchViewToEdges = (
  tournament: ReducerShape,
  matchView: MatchView
): Record<string, MappedEdge[]> => {
  const matchConfig = getSafe(tournament.matches, matchView.structuralId)
  const edges = [
    {
      sourceNodeId: matchConfig.structuralId,
      sourceNodeType: NodeType.Match,
      sourceSlot: 1,
      humanReadableString: `Winner of ${matchConfig.structuralId}`
    },
    {
      sourceNodeId: matchConfig.structuralId,
      sourceNodeType: NodeType.Match,
      sourceSlot: 2,
      humanReadableString: `Loser of ${matchConfig.structuralId}`
    }
  ]

  return edges.reduce(
    (acc, edge) => ({ ...acc, [edgeToHashString(edge)]: edge }),
    {}
  )
}

const mapCellContentsToMappedEdges = (
  tournament: ReducerShape,
  contents: MatchView[]
): Record<string, MappedEdge> =>
  contents.reduce(
    (acc, matchView) => ({
      ...acc,
      ...matchViewToEdges(tournament, matchView)
    }),
    {}
  )

const mapCellsToMappedEdges = (
  tournament: ReducerShape,
  cells: MatchCell[]
): Record<string, MappedEdge> => ({
  ...cells.reduce(
    (acc, cell) => ({
      ...acc,
      ...mapCellContentsToMappedEdges(tournament, cell.contents)
    }),
    {}
  )
})

export const buildEdgeMapForSection = (
  tournament: ReducerShape,
  section: NormalizedSection
): EdgeMap => {
  const decisionPoints = section.decisionPointIds.map((id) =>
    getSafe(tournament.decisionPoints, id)
  )

  const sourceEdges = buildSourceEdgesFromSeeding(section).reduce(
    (acc, edge) => ({ ...acc, [`seeding-${edge.sourceSlot}`]: edge }),
    {}
  )
  const decisionPointEdges = buildSourceEdgesFromDecisionPoints(
    decisionPoints as DecisionPointConfig[]
  ).reduce((acc, edge) => ({ ...acc, [edgeToHashString(edge)]: edge }), {})
  const alwaysAvailableEdges = {
    null: { ...sourceEdges, ...decisionPointEdges }
  }

  const columnEdges = section.columns.reduce(
    (acc, column, index) => ({
      ...acc,
      [index]: mapCellsToMappedEdges(tournament, column.cells)
    }),
    {}
  )

  return { ...alwaysAvailableEdges, ...columnEdges }
}

export const buildTargetEdgesFromDecisionPoints = (
  decisionPoints: DecisionPointConfig[]
): Partial<Edge>[] =>
  decisionPoints.flatMap((decisionPoint) =>
    decisionPoint.standing.entries.map((entry) => ({
      targetSlot: entry.index,
      targetNode: decisionPoint.structuralId,
      targetType: NodeType.DecisionPoint
    }))
  )

const edgeToDropdownOption = (edge: MappedEdge): DropdownOption => ({
  value: edgeToHashString(edge),
  label: edge.humanReadableString
})

const getColumnForMatch = (
  section: NormalizedSection,
  matchId: string | null
): number => {
  if (matchId === null) {
    return section.columns.length
  }
  else {
    return section.columns.findIndex((column) =>
      column.cells.some((cell) =>
        cell.contents.some((content) => content.structuralId === matchId)
      )
    )
  }
}

const edgeOptionsLeftOfColumn = (
  section: NormalizedSection,
  edgeMap: EdgeMap,
  entityId: string,
  column: number
) => {
  const head: (number | null)[] = [null]
  const columnsToInclude
    = !!column && column > 0
      ? head.concat(Array.from(Array(column - 1).keys()))
      : head

  const allPossibleEdges: Edge[] = columnsToInclude.flatMap((column) =>
    Object.values(getSafe(edgeMap, column as unknown as string))
  )

  const filteredEdges = allPossibleEdges
    .filter(
      (edge) =>
        !section.edges.some((existingEdge) =>
          sameSourceNode(edge, existingEdge)
        )
    )
    .filter((edge: Edge) => edge.targetNodeId !== entityId)

  return filteredEdges.map((edge) => edgeToDropdownOption(edge))
}

export const edgeOptionsForDecisionPoint = (
  section: NormalizedSection,
  edgeMap: EdgeMap,
  decisionPointId: string
): DropdownOption[] => {
  const edges = section.edges.filter(
    (edge) => edge.targetNodeId === decisionPointId
  )

  const maxColumn = Math.max(
    ...edges.map((edge: Edge) => getColumnForMatch(section, edge.sourceNodeId))
  )

  return edgeOptionsLeftOfColumn(section, edgeMap, decisionPointId, maxColumn)
}

export const edgeOptionsForMatch = (
  section: NormalizedSection,
  edgeMap: EdgeMap,
  matchId: string
): DropdownOption[] => {
  const matchColumn = getColumnForMatch(section, matchId)

  return edgeOptionsLeftOfColumn(section, edgeMap, matchId, matchColumn)
}

export const getEdgeSourceFromHash = (
  edgeMap: EdgeMap,
  hash: string
): Partial<Edge> => {
  const flattenedEdges = Object.values(edgeMap).reduce(
    (acc, edges) => ({ ...acc, ...edges }),
    {}
  )

  return getSafe(flattenedEdges, hash, {} as Partial<Edge>)
}

export const removeEdgesForEntity = (
  segment: TournamentSegment,
  entityId: string
): Edge[] =>
  segment.edges.filter(
    (oldEdge) =>
      oldEdge.targetNodeId !== entityId && oldEdge.sourceNodeId !== entityId
  )

export const replaceEdge = <T extends HasEdges>(segment: T, edge: Edge): T => ({
  ...segment,
  edges: segment.edges
    .filter((oldEdge) => !sameTargetNode(edge, oldEdge))
    .concat(edge)
})

export const removeEdge = <T extends HasEdges>(
  segment: T,
  targetNodeId: string,
  targetSlot: number
): T => ({
    ...segment,
    edges: segment.edges.filter(
      (edge) => !sameTargetNode(edge, { targetNodeId, targetSlot })
    )
  })

export const getHumanReadableLabelForEdge = (edge: Edge): string => {
  if (edge.sourceNodeId === null) {
    return `${toOrdinal(edge.sourceSlot)} Seed`
  }

  return `${edge.sourceNodeId} - ${toOrdinal(edge.sourceSlot)}`
}

export const getHumanReadableLabelForLineupEntry = (
  edges: Edge[],
  structuralId: string,
  entry: LineupEntry,
  fallback = 'Participant'
): string => {
  const edge = edges.find((edge) =>
    sameTargetNode(edge, {
      targetNodeId: structuralId,
      targetSlot: entry.index
    })
  )

  return edge ? getHumanReadableLabelForEdge(edge) : fallback
}

export const getMatchesInColumn = (column: MatchColumn): string[] =>
  column.cells.flatMap((cell) =>
    cell.contents.map((match) => match.structuralId)
  )

const filterConnectedEdges = (connectedEdges: Edge[]) => (edge: Edge) =>
  connectedEdges
    .map(fixEdge)
    .every(
      (cEdge) => !sameSourceNode(cEdge, edge) || sameTargetNode(cEdge, edge)
    )

export const getPossibleEdgesForDecisionPoint = (
  section: NormalizedSection,
  structuralId: string,
  slot: number
) => {
  // A Decision Point can have input from any match that appears EARLIER than
  // the earliest (leftmost) match in its Standing.  To find this, we do the
  // following:
  // 1) Build a list of all matches in the DP's outputs
  // 2) Find the leftmost match (smallest column index)
  // 3) Create a list of all possible edges from matches / seeding that appear
  //    in earlier columns
  // 4) Filter the above list to remove any potential edge which has already
  //    been used (e.g. that output is mapped to another input already)
  const matchesInStanding = section.edges
    .filter((edge) => edge.sourceNodeId === structuralId)
    .map((edge) => edge.targetNodeId)

  const leftmostMatchColumn = Math.min(
    section.columns.length,
    ...matchesInStanding.map((matchId) => getColumnForMatch(section, matchId))
  )

  const edgesFromSeeding = section.seeding.entries.map((seed) =>
    createEdge({
      sourceNodeId: null as unknown as string,
      sourceSlot: seed.index,
      targetNodeId: structuralId,
      targetSlot: slot
    })
  )

  const edgesFromMatches
    = leftmostMatchColumn > 0
      ? createArray(leftmostMatchColumn)
        .flatMap((index) => getMatchesInColumn(section.columns[index]))
        .flatMap((matchId) => [
          createEdge({
            sourceNodeId: matchId,
            sourceSlot: 1,
            targetNodeId: structuralId,
            targetSlot: slot
          }),
          createEdge({
            sourceNodeId: matchId,
            sourceSlot: 2,
            targetNodeId: structuralId,
            targetSlot: slot
          })
        ])
      : []

  return edgesFromSeeding
    .concat(edgesFromMatches)
    .filter(filterConnectedEdges(section.edges))
}

export const getPossibleEdgesForMatchSlot = (
  section: NormalizedSection,
  decisionPoints: Partial<DecisionPointConfig>[],
  matchId: string,
  slot: number
) => {
  const matchColumnIndex = getColumnForMatch(section, matchId)
  const upstreamMatchIds = createArray(matchColumnIndex).flatMap((index) =>
    getMatchesInColumn(section.columns[index])
  )

  // Create edges going from Section Seeding into this match slot
  const edgesFromSeeding = section.seeding.entries.map((seed) =>
    createEdge({
      sourceNodeId: null as unknown as string,
      sourceSlot: seed.index,
      targetNodeId: matchId,
      targetSlot: slot
    })
  )

  // Create edges going from all decision points into this match slot
  const edgesFromDecisionPoints = decisionPoints
    .filter((decisionPoint) =>
      decisionPoint.seeding?.entries.every((entry) => {
        // Remove all Decision Points which are seeded by matches that appear
        // later than this match.  It would be impossible for that decision point
        // to feed into this match.
        const edge = section.edges.find(
          (edge) =>
            edge.targetNodeId === decisionPoint.structuralId
            && edge.targetSlot === entry.index
        )

        return edge && edge.sourceNodeId
          ? getColumnForMatch(section, edge.sourceNodeId) < matchColumnIndex
          : true
      })
    )
    .flatMap((decisionPoint) =>
      decisionPoint.standing?.entries.map((entry) =>
        createEdge({
          sourceNodeId: decisionPoint.structuralId,
          sourceSlot: entry.index,
          targetNodeId: matchId,
          targetSlot: slot
        })
      )
    ) as Edge[]

  // Create edges going from all upstream matches into this match slot
  const edgesFromMatches = upstreamMatchIds.flatMap((id) => [
    createEdge({
      sourceNodeId: id,
      sourceSlot: 1,
      targetNodeId: matchId,
      targetSlot: slot
    }),
    createEdge({
      sourceNodeId: id,
      sourceSlot: 2,
      targetNodeId: matchId,
      targetSlot: slot
    })
  ])

  // Filter the list of possible edges to remove sources which have already been
  // connected to another part of the graph
  return edgesFromSeeding
    .concat(edgesFromDecisionPoints)
    .concat(edgesFromMatches)
    .filter(filterConnectedEdges(section.edges))
}

export const getPossibleEdgesForStanding = (
  section: NormalizedSection,
  targetSlot: number,
  decisionPoints: Partial<DecisionPointConfig>[]
) => {
  const matchIds = section.columns.flatMap(getMatchesInColumn)

  // Create edges going from all upstream matches into this match slot
  const edgesFromMatches = matchIds.flatMap((id) => [
    createEdge({
      sourceNodeId: id,
      sourceSlot: 1,
      targetNodeId: null as unknown as string,
      targetSlot
    }),
    createEdge({
      sourceNodeId: id,
      sourceSlot: 2,
      targetNodeId: null as unknown as string,
      targetSlot
    })
  ])

  decisionPoints?.forEach((decisionPoint) => {
    decisionPoint.standing?.entries.forEach((entry) => {
      const edge = createEdge({
        sourceNodeId: decisionPoint.structuralId,
        sourceSlot: entry.index,
        targetNodeId: null as unknown as string,
        targetSlot
      })

      edgesFromMatches.push(edge)
    })
  })

  return edgesFromMatches.filter(filterConnectedEdges(section.edges))
}

export const getOptionValueForMatchSlot = (
  section: NormalizedSection,
  structuralId: string | null,
  slot: number
): string | null => {
  const edge = section.edges
    .map(fixEdge)
    .find(
      (edge) => edge.targetNodeId === structuralId && edge.targetSlot === slot
    )

  return edge ? JSON.stringify(edge) : null
}

export const createEdgesForTournament = (model: ReducerShape) => {
  const { tournament, stages } = model

  const stageIds = tournament.stageIds || []

  // Reverse the order of the stages so that we can easily parse them backwards
  const reversedStages = [...stageIds]
    .reverse()
    .map((id) => getSafe(stages, id))

  const standingEdges = tournament.standing
    ? tournament.standing.entries.map((entry) => {
      // Find the latest stage in the tournament with enough participants for this
      // entry.
      const stage = reversedStages.find(
        (stage) => stage.standing.entries.length >= entry.index
      )

      return createEdge({
        sourceSlot: entry.index,
        sourceNodeId: stage
          ? stage.structuralId
          : (null as unknown as string),
        targetSlot: entry.index,
        targetNodeId: null as unknown as string
      })
    })
    : []

  const stageEdges = reversedStages.flatMap((stage, index) =>
    stage.standing.entries.map((entry) => {
      const sourceStage = reversedStages.find(
        (candidate, cIndex) =>
          cIndex > index && candidate.standing.entries.length >= entry.index
      )

      return createEdge({
        sourceSlot: entry.index,
        sourceNodeId: sourceStage
          ? sourceStage.structuralId
          : (null as unknown as string),
        targetSlot: entry.index,
        targetNodeId: stage.structuralId
      })
    })
  )

  return [...standingEdges, ...stageEdges]
}

/**
 * This function determines the value used to increment the target slot. It counts
 * the subsequent sections, including the current section, that will use up a slot
 * for the current entry index. It then counts the sections, excluding the current section,
 * that will use up slots for the next entry index.
 */
const getTargetSlotIncrement = (
  sections: NormalizedSection[],
  entryIndex: number,
  curSectionIndex: number
): number => {
  const numberOfSectionsWithSameEntryIndex = sections.filter(
    (section, sectionIndex) => 
      sectionIndex >= curSectionIndex
        && section.seeding.entries.length >= entryIndex
      
  ).length

  const nextEntryIndex = entryIndex + 1

  const numberOfSectionsWithNextEntryIndex = sections.filter(
    (section, sectionIndex) => 
      sectionIndex < curSectionIndex
        && section.seeding.entries.length >= nextEntryIndex
      
  ).length

  return numberOfSectionsWithSameEntryIndex + numberOfSectionsWithNextEntryIndex
}

export const createEdgesForStage = (model: ReducerShape, stageId: string) => {
  const stage = getSafe(model.stages, stageId)
  const sections = stage.sectionIds.map((id) => getSafe(model.sections, id))

  let curSourceSlot = 0
  return sections.reduce((edges, section, curSectionIndex) => {
    let targetSourceSlot = curSectionIndex + 1
    const sectionEdges = section.seeding.entries.reduce((acc, entry) => {
      const incomingEdge
        // Creates the "input" Edge
        = createEdge({
          // By incrementing from edges.length we ensure that Group B picks up
          // where Group A left off.  E.G. Group A pulls from Seeds 0-3, Group B
          // pulls from 4-7, etc.
          sourceSlot: curSourceSlot + entry.index,
          // ELDS interprets null here to mean "seeding"
          sourceNodeId: null as unknown as string,
          targetSlot: entry.index,
          targetNodeId: section.structuralId
        })

      const outgoingEdge = createEdge({
        sourceSlot: entry.index,
        sourceNodeId: section.structuralId,
        //  [2//22021] @MHadley
        // Since sections are the same size and sorted based on the stage's sections id
        // we can simple use the index of the section we are inspecting
        //  and the index * the number of sections to get the order
        // [4/2024] @sgorti
        // The previous implementation was correct as long as sections were the same size or
        // the odd sections occured first. This new implementation is more robust and will
        // work for sections that are not the same size or are not ordered in the same way.
        // For each entry we calculate the number of sections that are after it that do not
        // have the same number of entries as the current section. This gives us the correct
        // amount to increment the target slot by
        targetSlot: targetSourceSlot,
        targetNodeId: null as unknown as string
      })
      targetSourceSlot += getTargetSlotIncrement(
        sections,
        entry.index,
        curSectionIndex
      )
      return acc.concat([incomingEdge, outgoingEdge])
    }, [] as Edge[])
    curSourceSlot += section.seeding.entries.length
    return edges.concat(sectionEdges)
  }, [] as Edge[])
}
