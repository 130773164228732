import {
  DropType,
  PSAType,
  RecordStatus,
  StoreType
} from '@riotgames/api-types/drops/Common.type'
import {
  InventoryDescription,
  InventoryRequestDTO,
  InventoryResponseDTO
} from '@riotgames/api-types/drops/DropsV2.type'
import { isEqual } from '../../../../../commons'
import { Action, PayloadAction } from '../../../../Store.type'
import { createSlice } from '../../../../actions/utils'
import {
  cloneInventoryAction,
  createInventoryItemSuccess,
  editLocalizationKeys,
  resetInventoryItem
} from '../../../../actions/Drops/Drops.actions'

const initialState: Partial<InventoryRequestDTO> = {
  internalTitle: undefined,
  titleGroupKey: undefined,
  descriptionGroupKey: undefined,
  psaType: PSAType.CAPWalletItem,
  dropType: DropType.Psa,
  storeType: undefined,
  itemType: undefined,
  quantity: 1,
  imageUrl: undefined,
  gameItemID: undefined,
  status: RecordStatus.Active
}

const { reducer, actions } = createSlice({
  name: 'inventoryItem',
  initialState: initialState,
  reducers: {
    editDropType: (state, { payload }: Action<DropType>) => ({
      ...state,
      dropType: payload,
      psaType: undefined,
      quantity: 1,
      itemType: undefined,
      gameItemID: undefined,
      storeType: undefined
    }),
    editPsaType: (state, { payload }: Action<PSAType>) => ({
      ...state,
      psaType: payload,
      storeType: undefined,
      itemType: undefined,
      gameItemID: undefined,
      quantity: 1
    }),
    editQuantity: (state, { payload }: Action<number>) => ({
      ...state,
      quantity: payload
    }),
    editItemId: (state, { payload }: Action<string>) => ({
      ...state,
      gameItemID: payload
    }),
    editItemType: (state, { payload }: Action<string>) => ({
      ...state,
      itemType: payload
    }),
    editStoreType: (state, { payload }: Action<StoreType>) => ({
      ...state,
      storeType: payload
    }),
    editAssetUrl: (state, { payload }: Action<string>) => ({
      ...state,
      imageUrl: payload
    }),
    editInternalTitle: (state, { payload }: Action<string>) => ({
      ...state,
      internalTitle: payload
    }),
    editLocalizedFulfillmentStatusDescriptions: (
      state,
      { payload }: Action<InventoryDescription[]>
    ) => ({
      ...state,
      descriptions: payload
    })
  },
  extraReducers: {
    [editLocalizationKeys.type]: (
      state,
      { payload }: PayloadAction<{ title: string; description: string }>
    ) => ({
      ...state,
      titleGroupKey: payload.title,
      descriptionGroupKey: payload.description
    }),
    [cloneInventoryAction.type]: (
      state,
      { payload }: PayloadAction<{ inventoryItem: InventoryResponseDTO }>
    ) => ({
      internalTitle: payload.inventoryItem.internalTitle,
      titleGroupKey: payload.inventoryItem.titleKey,
      descriptionGroupKey: payload.inventoryItem.descriptionKey,
      descriptions: payload.inventoryItem.descriptions,
      psaType: payload.inventoryItem.psaType,
      storeType: payload.inventoryItem.storeType,
      itemType: payload.inventoryItem.itemType,
      dropType: payload.inventoryItem.dropType,
      quantity: payload.inventoryItem.quantity,
      imageUrl: payload.inventoryItem.imageUrl,
      gameItemID: payload.inventoryItem.gameItemID,
      status: RecordStatus.Active
    }),
    [resetInventoryItem.type]: () => initialState,
    [createInventoryItemSuccess.type]: () => initialState
  }
})

export default reducer
const {
  editDropType,
  editPsaType,
  editQuantity,
  editItemId,
  editItemType,
  editStoreType,
  editAssetUrl,
  editInternalTitle,
  editLocalizedFulfillmentStatusDescriptions,
  editDefaultLocalizedFulfillmentStatusDescriptions
} = actions
export {
  editDropType,
  editPsaType,
  editQuantity,
  editItemId,
  editItemType,
  editStoreType,
  editAssetUrl,
  editInternalTitle,
  editLocalizedFulfillmentStatusDescriptions,
  editDefaultLocalizedFulfillmentStatusDescriptions
}

export const getWIPInventoryItem = (
  state: Partial<InventoryRequestDTO>
): Partial<InventoryRequestDTO> => state
export const isInventoryItemDirty = (
  state: Partial<InventoryRequestDTO>
): boolean => !isEqual(state, initialState)
export const isInventoryItemChampionSkin = (
  state: Partial<InventoryRequestDTO>
): boolean =>
  state.dropType === DropType.Psa
  && state.storeType === StoreType.Championsskin
  && state.psaType === PSAType.LoLStore
