import { LeagueConfigurationStatus } from '../../services/BannersService/BannersService.type'
import { NavItems } from './Nav.type'

export const navItems: NavItems = [
  {
    name: 'Leagues',
    link: '/leagues/list/current'
  },
  {
    name: 'Seasons',
    link: '/season/list/current'
  },
  {
    name: 'Tournaments',
    link: '/tournament/list/current'
  },
  {
    name: 'Teams',
    link: '/teams/list/current'
  },
  {
    name: 'Players',
    link: '/players/list/current'
  },
  {
    name: 'Streams',
    link: '/stream/group/list'
  },
  {
    name: 'Orgs',
    link: '/organizations/list/active'
  },
  {
    name: 'Drops',
    link: '/drops/list/current'
  },
  {
    name: 'Banners',
    link: `/banners/list/${LeagueConfigurationStatus.NeedsScreenshots}`
  },
  {
    name: 'Accounts',
    link: '/accounts/list'
  },
  {
    name: 'Pickem',
    link: '/pickem/leagues/list'
  },
  {
    name: 'Shotcall',
    link: '/shotcall/questions'
  },
  {
    name: 'User',
    link: '/usermanagement'
  }
]
