import { LegacyRef, MouseEvent, useEffect, useRef, useState } from 'react'
import arrowImage from '../../assets/svg/arrow.svg'
import { Logger, classNames, stopEventPropagation } from '../../commons'
import WithValidationStateless from '../WithValidation/WithValidationStateless'
import styles from './Dropdown.scss'
import { DropdownOption, DropdownValue, Props } from './Dropdown.type'
import ClickoutDetector from '../../components/ClickoutDetector/ClickoutDetector'
import WithTitle from '../../components/WithTitle/WithTitle'

// A simple factory method for creating basic dropdown options.  Should support
// the most common use cases, but will not be one size fits all.
const createOption = (
  label: string,
  value: DropdownValue,
  icon?: string | null,
  menu?: DropdownOption[]
): DropdownOption => ({
  label,
  value,
  icon,
  menu
})

const log = new Logger('Dropdown')

const Dropdown = ({
  onSelect,
  onToggle,
  options,
  value,
  class: className,
  currentOptionClass,
  disabled,
  hideSelectedInMenu,
  menuClass,
  optionClass,
  sportSelector,
  title
}: Props): JSX.Element => {
  const [dropDownStatus, setDropDownStatus] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>()

  const getSelectedOption = (): DropdownOption => {
    if (value) {
      return options.find((option) => option.value === value) ?? options[0]
    }
    return options[0]
  }

  const hideMenu = (): void => {
    onToggle?.(false)
    setDropDownStatus(false)
  }

  const toggleMenu = (event?: MouseEvent, disabled?: boolean): void => {
    if (disabled) {
      return
    }
    event && stopEventPropagation(event)
    onToggle?.(!dropDownStatus)
    setDropDownStatus(!dropDownStatus)
  }

  const renderIcon = (option: DropdownOption): JSX.Element | null => {
    const hasAtLeastOneIcon = options.some((option) => !!option.icon)
    if (!hasAtLeastOneIcon) {
      return null
    }
    return (
      <div className={ styles.icon } data-no-scrollable>
        { option.icon && <img src={ option.icon }/> }
      </div>
    )
  }

  const optionsHaveNoLabels = () =>
    !options.some(
      (option) =>
        option.label !== ''
        && option.label !== null
        && option.label !== undefined
    )

  const renderLabel = (option: DropdownOption) => {
    if (optionsHaveNoLabels()) {
      return
    }
    return (
      <div className={ styles.text } data-no-scrollable>
        { option.label }
      </div>
    )
  }

  const renderChevron = (): JSX.Element => 
    <img src={ arrowImage } className={ styles.chevron } data-no-scrollable/>

  const selectOption = (option: DropdownOption): void => {
    toggleMenu()
    onSelect && onSelect(option)
  }

  const renderOption = (
    option: DropdownOption,
    selectedValue: DropdownValue
  ): JSX.Element => {
    const classes = classNames(
      styles.option,
      optionClass,
      option.value === selectedValue && styles.selected
    )
    const key = `${option.label}${option.value}`
    return (
      <div
        data-testid={ `dropdown-option-${option.value}` }
        className={ classes }
        onClick={ (event): void => {
          event.stopPropagation()
          selectOption(option)
        } }
        key={ key }>
        { renderIcon(option) }
        <div className={ styles.text }>{ option.label }</div>
      </div>
    )
  }

  const renderCurrentOption = (disabled: boolean): JSX.Element | null => {
    const option = getSelectedOption()
    const classes = classNames(styles.currentOption, currentOptionClass)

    if (!option) {
      log.debug('No current option found.')
      return null
    }

    return (
      <div
        key={ option.label }
        data-testid="current-option"
        className={ classes }
        data-no-scrollable
        onClick={ (event: MouseEvent): void => {
          toggleMenu(event, disabled)
        } }>
        { renderIcon(option) }
        { renderLabel(option) }
        { renderChevron() }
      </div>
    )
  }

  const renderOptionsMenu = (options: DropdownOption[]): JSX.Element => {
    const { value } = getSelectedOption()

    // Dropdowns that appear close to the bottom of the screen should open upwards
    // to prevent their contents from being clipped by the bottom of the screen.
    // 400 here represents the max height of the dropdown menu plus some buffer
    // to represent the height of the dropdown itself and some extra space.
    const dropup
      = window.innerHeight
        - (ref.current as HTMLDivElement).getBoundingClientRect().y
      < 400

    const classes = classNames(
      styles.options,
      dropup ? styles.up : styles.down,
      menuClass
    )

    const filteredOptions = hideSelectedInMenu
      ? options.filter((option) => option.value !== value)
      : options

    return (
      <div data-testid="dropdown-menu" className={ classes }>
        { filteredOptions.map((option) => renderOption(option, value)) }
      </div>
    )
  }

  const renderTitle = (title: string): JSX.Element => (
    <div data-testid="dropdown-title" className={ styles.title }>
      { title }
    </div>
  )

  useEffect(() => {
    const selectedOption = getSelectedOption()
    if (selectedOption && selectedOption.value !== value) {
      onSelect && onSelect(getSelectedOption())
    }
  }, [])

  const classes = classNames(
    styles.dropdown,
    dropDownStatus && styles.open,
    disabled && styles.disabled,
    optionsHaveNoLabels() && styles.iconOnly,
    sportSelector && styles.sportSelector,
    className
  )

  const clickoutClasses = classNames(styles.optionContainer)

  if (!options || !options.length) {
    log.error('Dropdown initialized with no available options.')
    return <></>
  }

  return (
    <div
      tabIndex={ 0 }
      onSelect={ () => onSelect }
      className={ classes }
      ref={ ref as LegacyRef<HTMLDivElement> }>
      <ClickoutDetector
        onClickout={ () => hideMenu() }
        className={ clickoutClasses }>
        { title && renderTitle(title) }
        { renderCurrentOption(!!disabled) }
        { dropDownStatus && renderOptionsMenu(options) }
      </ClickoutDetector>
    </div>
  )
}

export { Dropdown, createOption }

export const ValidatedDropdown = WithValidationStateless(Dropdown)
export const TitledDropdown = WithTitle(Dropdown)
