import { Envs } from '@riotgames/commons/env'
import Logger from '../../commons/Logger/Logger'

const log = new Logger('EmpLambdaConfig')
const defaultEnv = Envs.Prod

class EmpLambdaConfig {
  static getLambdaUrl (): string {
    const { EMP_DROP_IMG_LAMBDA } = process.env as any
    const lambdaUrl = EMP_DROP_IMG_LAMBDA ?? defaultEnv
    log.debug(`EMP_DROP_IMG_LAMBDA: ${lambdaUrl}`)
    return lambdaUrl
  }

  static getLambdaAuth (): string {
    const { EMP_DROP_IMG_LAMBDA_AUTH } = process.env as any
    const lambdaAuth = EMP_DROP_IMG_LAMBDA_AUTH ?? defaultEnv
    log.debug(
      `EMP_DROP_IMG_LAMBDA_AUTH: ${lambdaAuth.substring(0, 5)}...[secret]`
    )
    return lambdaAuth
  }
}

export default EmpLambdaConfig
