import { Env, Envs } from '@riotgames/commons/env'
import Logger from '../../commons/Logger/Logger'

const log = new Logger('EmpApiConfig')
const defaultEnv = Envs.Prod

export enum ServiceCategory {
  Admin = 'admin',
  AuditLog = 'auditLog',
  Banners = 'banners',
  Drops = 'drops',
  Elds = 'elds',
  LiveStats = 'liveStats',
  PickemLifecycle = 'pickemLifecycle',
  Pickem = 'pickem',
  PromoCodes = 'promoCodes',
  StreamTimeline = 'streamTimeline',
  Shotcall = 'shotcall',
  ViewerAccount = 'viewerAccount',
}

// TODO: Update Test, Prod Url once BE Team completes EMP Service deployment
const baseApiMap: Dict<Env> = {
  [Envs.Local]: 'dev-emp-api.service.riotesports.com',
  [Envs.Dev]: 'dev-emp-api.service.riotesports.com',
  [Envs.Test]: 'test-emp-api.service.riotesports.com',
  [Envs.Prod]: 'emp-api.service.riotesports.com'
}

// TODO: Update Test, Prod Url once BE Team completes EMP Service deployment
const apiMap: Dict<Dict<Env>> = {
  [ServiceCategory.Admin]: baseApiMap,
  [ServiceCategory.AuditLog]: baseApiMap,
  [ServiceCategory.Banners]: baseApiMap,
  [ServiceCategory.Drops]: {
    ...baseApiMap,
    [Envs.Stage]: 'stage-emp-api.service.riotesports.com'
  },
  [ServiceCategory.Elds]: baseApiMap,
  [ServiceCategory.LiveStats]: baseApiMap,
  [ServiceCategory.Pickem]: {
    ...baseApiMap,
    [Envs.Stage]: 'stage-emp-api.service.riotesports.com'
  },
  [ServiceCategory.PickemLifecycle]: {
    ...baseApiMap,
    [Envs.Stage]: 'stage-emp-api.service.riotesports.com'
  },
  [ServiceCategory.PromoCodes]: {
    ...baseApiMap,
    [Envs.Stage]: 'stage-emp-api.service.riotesports.com'
  },
  [ServiceCategory.StreamTimeline]: {
    ...baseApiMap,
    [Envs.Stage]: 'stage-emp-api.service.riotesports.com'
  },
  [ServiceCategory.Shotcall]: baseApiMap,
  [ServiceCategory.ViewerAccount]: baseApiMap
}

const requireAccessToken = [
  'gamemanagement',
  'konfigurator',
  'elds',
  'fandom-account',
  'dropInventoryItem',
  'rarity',
  'drops/group',
  'dropTrigger'
]
class EmpApiConfig {
  static injectAccessToken (): boolean {
    const { EMP_SERVICE_API_INJECT_ACCESS_TOKEN } = process.env as any
    // webpack define variable plugin will respect variable type provided as env
    const inject = !!EMP_SERVICE_API_INJECT_ACCESS_TOKEN
    return inject
  }

  static requireAccessTokenList (): string[] {
    return requireAccessToken
  }

  static getApiBaseUrl (
    category: string,
    env: string,
    domainOnly: boolean = false
  ): string {
    const url = apiMap[category][env]
    let baseUrl = `https://${url}`
    if (!domainOnly) {
      baseUrl = `${baseUrl}/api`
    }
    return baseUrl
  }
}

export default EmpApiConfig
