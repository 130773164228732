/**
 * Mapping of localization slugs to english labels. Represents the list of
 * approved stage and section names for any given tournament. This list must be a subset
 * of the list of localized "stage_names" in elds-localizations.
 *
 * How to add translations
 * 1. Add translations to the "stage_name" property of this file https://gh.riotgames.com/esports/elds-localizations/blob/master/locale/en-US.json
 * 2. Follow this process to request translations https://gh.riotgames.com/esports/elds-localizations/tree/master?tab=readme-ov-file#updating-translations
 * 3. Update the list below with the new slug and english name. Create a short name (code property)
 */

export const stageAndSectionNames: Record<
  string,
  { name: string; code: string }
> = {
  north_qualifier: { name: 'North Qualifier', code: 'NQ' },
  south_qualifier: { name: 'South Qualifier', code: 'SQ' },
  ascend_promotion: { name: 'Ascend Promotion', code: 'ASCP' },
  bottom_4: { name: 'Bottom 4', code: 'BOT4' },
  top_4: { name: 'Top 4', code: 'TOP4' },
  breakout_group: { name: 'Breakout Group', code: 'BG' },
  contender_group: { name: 'Contender Group', code: 'CG' },
  elimination_zone: { name: 'Elimination Zone', code: 'EZ' },
  seeding_bracket: { name: 'Seeding Bracket', code: 'SB' },
  group_seeding_bracket: { name: 'Group Seeding Bracket', code: 'GSB' },
  rise_group: { name: 'Rise Group', code: 'RG' },
  legend_group: { name: 'Legend Group', code: 'LG' },
  qualifying_series: { name: 'Qualifying Series', code: 'QS' },
  regular_split_part_1: { name: 'Regular Split - Part 1', code: 'RSP1' },
  regular_split_part_2: { name: 'Regular Split - Part 2', code: 'RSP2' },
  road_to_msi: { name: 'Road to MSI', code: 'RMSI' },
  group_stage: { name: 'Group Stage', code: 'GST' },
  play_ins: { name: 'Play-Ins', code: 'PI' },
  play_ins_draw_show: { name: 'Play-Ins Draw Show', code: 'PIDS' },
  bracket_stage_draw_show: { name: 'Bracket Stage Draw Show', code: 'BSDS' },
  bracket_stage: { name: 'Bracket Stage', code: 'BS' },
  playoff_play_ins: { name: 'Playoff Play-Ins', code: 'PPI' },
  playoff_play_in_knights_rival: {
    name: 'Playoff Play-In Knights Rival',
    code: 'PPKR'
  },
  rumble_stage: { name: 'Rumble Stage', code: 'RST' },
  na: { name: 'NA', code: 'NA' },
  emea: { name: 'EMEA', code: 'EMEA' },
  jp: { name: 'JP', code: 'JP' },
  sea: { name: 'SEA', code: 'SEA' },
  kr: { name: 'KR', code: 'KR' },
  br: { name: 'BR', code: 'BR' },
  latam: { name: 'LATAM', code: 'LATAM' },
  play_in_groups: { name: 'Play-In Groups', code: 'PIG' },
  play_in_elim: { name: 'Play-In Knockout', code: 'PIK' },
  groups: { name: 'Groups', code: 'GS' },
  knockouts: { name: 'Knockouts', code: 'KO' },
  regular_season: { name: 'Regular Season', code: 'RS' },
  playoffs: { name: 'Playoffs', code: 'PO' },
  tiebreaker: { name: 'Tiebreaker', code: 'TB' },
  exhibition: { name: 'Exhibition', code: 'EXB' },
  winners_bracket: { name: "Winner's Bracket", code: 'WB' },
  losers_bracket: { name: "Loser's Bracket", code: 'LB' },
  group_a: { name: 'Group A', code: 'GA' },
  group_b: { name: 'Group B', code: 'GB' },
  group_c: { name: 'Group C', code: 'GC' },
  group_d: { name: 'Group D', code: 'GD' },
  group_1: { name: 'Group 1', code: 'G1' },
  group_2: { name: 'Group 2', code: 'G2' },
  group_3: { name: 'Group 3', code: 'G3' },
  group_4: { name: 'Group 4', code: 'G4' },
  round_1: { name: 'Round 1', code: 'R1' },
  round_2: { name: 'Round 2', code: 'R2' },
  round_3: { name: 'Round 3', code: 'R3' },
  round_4: { name: 'Round 4', code: 'R4' },
  round_5: { name: 'Round 5', code: 'R5' },
  group_ascend: { name: 'Group Ascend', code: 'GASC' },
  group_nirvana: { name: 'Group Nirvana', code: 'GNIR' },
  regional_finals: { name: 'Regional Finals', code: 'RF' },
  regional_qualifier: { name: 'Regional Qualifier', code: 'RQ' },
  swiss: { name: 'Swiss', code: 'SW' },
  weekend_1: { name: 'Weekend 1', code: 'W1' },
  weekend_2: { name: 'Weekend 2', code: 'W2' },
  weekend_3: { name: 'Weekend 3', code: 'W3' },
  weekend_4: { name: 'Weekend 4', code: 'W4' },
  weekend_5: { name: 'Weekend 5', code: 'W5' }
}
